/**
 * Created by Abner Sui on 08/20/2019
 * -----------------------------------
 * 11/16/2021 Simon Zhao extracted the delimiter of column definition id as a const, added an attach function for attaching extra configuration info to the task fields and re-enabled the number-info-aggrid component as the cell render for number type fields.
 * 1/6/2022 Simon Zhao added support of extracting format configuration from the task template.
 * 1/12/2022 Simon Zhao added a function to format numerical values with international service provided by kendo.
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { ProcessInstance, ProcessDefinition, TaskDefinition, Task, Stage, TaskStatus, TaskType, FieldType, Field, ProcessInstanceStatus, ConnectionLine, GatewayType, GatewayDefinition, FileInfo, FieldDefinition, COLORS, DONE_COLOR, REJECT_COLOR, ProjectHistory, NOT_START_TASK_FIELD_STYLE, DefaultColumnID } from '../models/workflow.model';
import { EntityBrief } from '../models/entity-brief.model';
import * as anchorme from 'anchorme';
import { DateHelperWebService } from './date-helper.web.service';
import { EntityType } from '../models/entity-type.model';
import { UtilsService } from './utils.service';
import { WorkflowActionTypes } from '../redux/actions/workflow.actions';
import { select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { workflowIdsSelector } from '../redux/reducers/workflow.reducer';
import { ControlType } from '../models/template-control.model';
import { IntlService } from '@progress/kendo-angular-intl';
import * as _lodash from 'lodash';
import { Entity } from '../models/entity.model';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';
import { ArrayHelperService } from '../services/array-helper.service';
import { AutomationModelBase } from '../models/automation-base.model';
import { EntryType } from '../models/entry-type.model';

export const ITEM_DELIMITER = ',';
export const ENTITY_DELIMITER = ';';
export const WHITE_SPACE_HTML = ';&nbsp;';
export const BLANK_VALUE = '(Blanks)';
export const EMPTY_VALUE = '';
export const NOT_START = 'Not Started';
export const COLUMN_ID_DELIMITER = '_';
export const COLUMN_TYPE_ATTRIB = 'columnType';
export const CELL_RENDER_FRAMEWORK_ATTRIB = 'cellRenderer';
export const FIX_FORMAT_AGG_FUNCTIONS = {
    count: {
        maximumFractionDigits: 9,
        minimumFractionDigits: 0,
        style: 'decimal',
        useGrouping: false
    }
};
export const NUMBER_FORMAT_ATTRIB = 'numberFormat';
export const PROJECT_ID_COLUMN_ID = 'project-id';
export const SOURCE_GRID = 'fromGrid';
/**
 * This parameter serves as an indicator of whether the cell content should be formatted into a link style.
 */
export const NOT_FORMAT_LINK_PARA_NAME = 'notFormatLink';
@Injectable()
export class WorkflowService {

    workflowActionSubject$: Subject<any> = new Subject();

    constructor(
        private _transportService: TransportService,
        private _utils: UtilsService,
        private _intl: IntlService
    ) { }

    static convertStageForeColorToBackground(foreColor: string) {
        // opacity 15%  = Hex +26
        return foreColor + 26;
    }

    /**
     * Get the format object applicable to the kendo numeric textbox control per the separator and decimal configuration.
     * @param config the numeric field configuration
     * @returns the format objection applicable to the kendo numeric textbox control.
     */
    static getNumberFormatBasedOnConfig(config: { separator: boolean, decimals: number }) {
        const format = {
            style: 'decimal',
            useGrouping: false,
            maximumFractionDigits: 9,
            minimumFractionDigits: 0,
        };
        if (config) {
            const decimals = config.decimals;
            if (decimals !== null && decimals !== undefined) {
                format.maximumFractionDigits = decimals;
                format.minimumFractionDigits = decimals;
            }
            format.useGrouping = config.separator;
        }

        return format;
    }

    /**
    * obtains the relative url to zoom the corresponding entity.
    * @param v the entity field value
    * @returns a relative url to zoom the corresponding entity.
    */
    static getEntityRelativeLink(v: Entity) {
        const getEntityRouteNameFunc = (et: Entity) => {
            if (et && et.type) {
                switch (et.type.name.toLowerCase()) {
                    case EntityType.CONTACT.name.toLowerCase():
                        return 'contact';
                    default:
                        return 'entity';
                }
            }

            return '';
        };

        return `${getEntityRouteNameFunc(v)}/${v.id}`;
    }

    /**
     * gets the brief content for a given check list field value.
     * @param field the value of a check list field.
     * @param checkLists the check lists
     * @returns the brief content of this field.
     */
    static getFieldBriefContent(field: Field, checkLists?: any[]): string {
        let resultStr = '';
        const fieldValue = field?.value;
        if (fieldValue) {
            if (fieldValue.toString() === NOT_START || fieldValue.toString() === BLANK_VALUE) {
                return fieldValue.toString();
            }

            let total = 0, checked = 0;
            Object.keys(fieldValue).forEach((k: string) => {
                if (fieldValue[k]) {
                    checked++;
                }
            });
            if (checkLists && checkLists.length > 0) {
                const checkList = checkLists.filter(item => item.fieldDefinitionId === field.fieldDefinition.id)[0];
                total = checkList.checkItems.length;
            }
            resultStr = `${checked}/${total}`;
        }
        return resultStr;
    }

    /**
     * obtains the cell content per the field definition and its value.
     * @param fieldDefinition the field definition.
     * @param params the context with a value property.
     * @param isForTooltip a flag indicating whether the return value is served in the format of pure string rather than hyperlinks.
     * @returns the content for rendering.
     */
    cellRenderer(fieldDefinition: FieldDefinition, params: { value: any }, isForTooltip = false, isFilter = false): any {
        const value: any = params.value;
        if (value === '' || value === BLANK_VALUE) {
            return BLANK_VALUE;
        }
        if (value === NOT_START) {
            return NOT_START;
        }
        const factor: boolean = value || value === 0;
        if (fieldDefinition.type === FieldType.NUMBER && factor) {
            let decimal = 9;
            let zeroize = false;
            let separator = true;
            if (fieldDefinition.configuration) {
                if (fieldDefinition.configuration.decimals !== null && fieldDefinition.configuration.decimals !== undefined) {
                    decimal = fieldDefinition.configuration.decimals;
                    zeroize = true;
                }
                if (fieldDefinition.configuration.separator !== null && fieldDefinition.configuration.separator !== undefined) {
                    separator = fieldDefinition.configuration.separator;
                }
            }
            const numberStr = this._utils.formatNumberStr(value.toString(), decimal, zeroize, separator);
            // the minus sign is meaningless for zero, so remove it.
            return Number(numberStr) === -0 ? '0' : numberStr;
        } else if (fieldDefinition.type === FieldType.CURRENCY && factor) {
            const isMinus: boolean = value < 0;
            let str: string = Math.abs(value).toString();
            str = this._utils.wipeOffScientificNotation(str);
            return isMinus ? '$(' + str + ')' : '$' + str;
        } else if ((fieldDefinition.type === FieldType.ENTITY || fieldDefinition.type === FieldType.User) && factor) {
            // support show an entity link for entity control(ed/met)
            const entityLinks = [];
            if (value && value instanceof Array) {
                // met
                let tooltipStr = '';
                value.forEach(item => {
                    entityLinks.push(`<a href="${WorkflowService.getEntityRelativeLink(item)}" target='_blank'>${item.shortName}</a>`);
                    if (tooltipStr.length > 0) {
                        tooltipStr += ITEM_DELIMITER;
                    }
                    tooltipStr += item.shortName;
                });
                // the return value is also used to obtain the tooltip, in that case the html is inappropriate, so simply return the short name of each entity.
                if (isForTooltip || isFilter) {
                    return tooltipStr;
                } else {
                    return entityLinks.join(WHITE_SPACE_HTML);
                }
            } else {
                const entityStr = !value ? BLANK_VALUE : !value.shortName ? value : value.shortName;
                if (isForTooltip) {
                    return entityStr;
                } else {
                    return entityStr !== BLANK_VALUE ? `<a href='${WorkflowService.getEntityRelativeLink(value)}' target='_blank'>${entityStr}</a>` : entityStr;
                }
            }
        } else if (fieldDefinition.type === FieldType.DATE && factor) {
            return DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(+value), 'MMM-dd-yyyy');
        } else if (fieldDefinition.type === FieldType.FILE && factor) {
            return value.map(item => item.fileName).join(', ');
        } else if (fieldDefinition.type === FieldType.CHECK_LIST && factor) {
            // support show an entity link for entity control(ed/met)
            let displayStr = BLANK_VALUE;
            if (value) {
                // to get check list
                const checkLists = [];
                if (params && params['data']) {
                    params['data'].task.forEach(task => {
                        task.taskDefinition.template?.contains.forEach(control => {
                            // get all check list to handle the display in the view
                            if (control[0].type === ControlType.CHECK_LIST) {
                                checkLists.push(control[0]);
                            }
                        });
                    });
                }
                const field = new Field();
                field.id = fieldDefinition.id;
                field.value = value;
                field.fieldDefinition = fieldDefinition;
                const brief = WorkflowService.getFieldBriefContent(field, checkLists);
                if (brief) {
                    displayStr = brief;
                }
            }

            return displayStr;
        }
        return value;
    }

    createWorkflowStage(name: string): Observable<any> {
        const url = `${AppConfig.workFlowStageEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('name', name);
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    createWorkflowBrief(data): Observable<any> {
        const url = `${AppConfig.screenEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('screen', JSON.stringify(data));
        formData.set('showpermission', 'true');
        const params = {
            expand: 'entity',
            showpermission: true,
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    checkDuplicatedName(screen): Observable<any> {
        const url = `${AppConfig.screenEndpoint}name`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('screen', JSON.stringify(screen));
        const options = {
            headers: headers
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    createTaskDefinition(data): Observable<any> {
        const url = `${AppConfig.workFlowTaskDefinitionEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        const params = {
            expand: 'stage;field',
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    createTaskDefinitionTemplate(template: any): Observable<any> {
        const url = `${AppConfig.workFlowTaskDefinitionTemplateEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(template));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    createWorkflowFieldDefinition(data): Observable<any> {
        const url = `${AppConfig.workFlowFieldEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    createWorkflowFieldDefinitionBatch(data): Observable<any> {
        const url = `${AppConfig.workFlowFieldEndpoint}batch/`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    createGateway(data): Observable<any> {
        const url = `${AppConfig.workFlowGateWayEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    deleteProcessInstanceById(id: string): Observable<any> {
        const url = `${AppConfig.workFlowProcessInstanceEndpoint}${id}`;
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this._transportService.delete(url, options);
    }

    deleteStageById(id: string): Observable<any> {
        const url = `${AppConfig.workFlowStageEndpoint}${id}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        return this._transportService.delete(url, options);
    }

    deleteTaskDefinition(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.workFlowTaskDefinitionEndpoint}${id}`;
        const options = {
            headers: headers,
        };
        return this._transportService.delete(url, options);
    }

    deleteTaskDefinitionBatch(ids: Array<string>): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.workFlowTaskDefinitionEndpoint}batch/`;
        const params = {
            'id-list': JSON.stringify(ids),
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.delete(url, options);
    }

    deleteTaskDefinitionTemplate(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.workFlowTaskDefinitionTemplateEndpoint}${id}`;
        const options = {
            headers: headers,
        };
        return this._transportService.delete(url, options);
    }

    editTaskDefinition(id, data): Observable<any> {
        const url = `${AppConfig.workFlowTaskDefinitionEndpoint}${id}`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(data));
        const params = {
            expand: 'stage;field',
        };
        const options = {
            params: params,
        };
        return this._transportService.put(url, formData, options);
    }

    formatLabelStrToLink(value): Object {
        const newValue = anchorme.default(value, {
            attributes: [
                (urlObj) => {
                    if (urlObj.protocol !== 'mailto:') {
                        return {
                            name: 'target',
                            value: '_blank'
                        };
                    }
                }
            ]
        });
        return newValue;
    }

    formatLabelStrToString(value: string): string {
        if (typeof value === 'string' && value) {
            value = value.replace(/(\<)/g, '&lt;').replace(/(\>)/g, '&gt;');
        }
        return value;
    }

    /**
     * formats the number passed in by the given format configuration.
     *
     * @param {number} value the raw number value.
     * @param {*} frmCfg the configured format.
     * @return {*}  the formated result.
     * @memberof WorkflowService
     */
    formatNumericalValueByConfig(value: number, frmCfg: any) {
        return this._intl.formatNumber(value, frmCfg);
    }

    generateColDefForInstanceByApproveTaskDefinition(taskDefinition: TaskDefinition): any {
        const result: any = {
            colId: taskDefinition.id,
            field: taskDefinition.id,
            headerName: taskDefinition.name,
            valueGetter: (params) => {
                const value = this.valueGetterFromInstanceForApproveTask(taskDefinition.id, params.data);
                if (!value) {
                    if (this.isNotStartTaskFieldForForApproveTask(taskDefinition.id, params.data)) {
                        return NOT_START;
                    }
                }
                return value || '';
            },
            cellRenderer: (params) => {
                if (params.value === NOT_START) {
                    return NOT_START_TASK_FIELD_STYLE;
                } else {
                    return params.value;
                }
            },
            filterParams: {
                valueGetter: (params) => {
                    const value = this.valueGetterFromInstanceForApproveTask(taskDefinition.id, params.data);
                    if (!value) {
                        if (this.isNotStartTaskFieldForForApproveTask(taskDefinition.id, params.data)) {
                            return NOT_START;
                        }
                    }
                    return value;
                }
            },
            enableRowGroup: true,
            maxWidth: 500,
            minWidht: 85,
        };
        return result;
    }

    generateColDefForInstanceByField(field: Field, taskDefinition: TaskDefinition): any {
        const result: any = {
            colId: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
            field: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
            headerName: field.fieldDefinition.name,
            columnType: field.fieldDefinition.type,
            columnConfig: field,
            tooltipValueGetter: (params) => {
                params[NOT_FORMAT_LINK_PARA_NAME] = true;
                const value = this.cellRenderer(field.fieldDefinition, params, true);
                return value;
            },
            valueGetter: (params) => {
                const value = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);
                if (!value) {
                    if (this.isNotStartTaskFieldForInputTask(field.fieldDefinition.id, taskDefinition.id, params.data)) {
                        return NOT_START;
                    }
                }
                return value || '';
            },
            cellRenderer: (params) => {
                let value = this.cellRenderer(field.fieldDefinition, params);
                if (value === BLANK_VALUE) {
                    value = EMPTY_VALUE;
                }
                if (this.isNotStartTaskFieldForInputTask(field.fieldDefinition.id, taskDefinition.id, params.data)) {
                    value = NOT_START_TASK_FIELD_STYLE;
                }

                return value;
            },
            filterParams: {
                valueGetter: (params) => {
                    let value = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);
                    params['value'] = value;
                    value = this.cellRenderer(field.fieldDefinition, params, false, true);
                    if (this.isNotStartTaskFieldForInputTask(field.fieldDefinition.id, taskDefinition.id, params.data)) {
                        value = NOT_START;
                    }

                    return value;
                }
            },
            maxWidth: 500,
            minWidth: 85
        };
        if (field.fieldDefinition.type === FieldType.NUMBER || field.fieldDefinition.type === FieldType.CURRENCY) {
            result.cellStyle = { textAlign: 'right' };
            result.enableValue = true;
            result.enableRowGroup = false;
        } else if (field.fieldDefinition.type === FieldType.ENTITY || field.fieldDefinition.type === FieldType.User || field.fieldDefinition.type === FieldType.TEXT) {
            result.enableRowGroup = true;
            if (field.fieldDefinition.type === FieldType.ENTITY || field.fieldDefinition.type === FieldType.User) {
                const parseEntityValue = (eValue: any) => {
                    if (Array.isArray(eValue)) {
                        // a valid entity value, i.e an array of entity objects.
                        return Array.from(eValue).map(et => et.shortName).join(ENTITY_DELIMITER);
                    } else {
                        return eValue;
                    }
                };
                // For a complex object, we need to tell ag-grid how to extract the content for displaying
                // this function is also effective for filtering, so do not need filter Parameters any more
                result.keyCreator = para => para && para.value ? parseEntityValue(para.value) : BLANK_VALUE;
            }
        } else if (field.fieldDefinition.type === FieldType.DATE) {
            result.enableRowGroup = true;
        } else if (field.fieldDefinition.type === FieldType.CHECK_LIST) {
            result.enableRowGroup = false;

            // For a complex object, we need to tell ag-grid how to extract the content for displaying
            // this function is also effective for filtering, so do not need filter Parameters any more.
            // to get check list
            const checkLists = [];
            taskDefinition?.template?.contains.forEach(control => {
                // get all check list to handle the display in the view
                if (control[0].type === ControlType.CHECK_LIST) {
                    checkLists.push(control[0]);
                }
            });
            result.keyCreator = this._setGridColumnKeyCreator.bind(this, checkLists, field);
        }
        return result;
    }

    generateColDefForInstanceSpecialField(field: Field, taskDefinition: TaskDefinition, cellComponent: Object, taskDefTemplate?: any): any {
        let result: any;
        let getters: { tooltipGetter: (params: any) => any, valueGetter: (params: any) => any, filterValueGetter: (params: any) => any };

        if (field.fieldDefinition.type === FieldType.FILE) {
            if (!getters) {
                getters = { tooltipGetter: null, valueGetter: null, filterValueGetter: null };
                getters.valueGetter = (params) => {
                    const value = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);
                    return value;
                };

                getters.tooltipGetter = (params) => {
                    params[NOT_FORMAT_LINK_PARA_NAME] = true;
                    const value = this.cellRenderer(field.fieldDefinition, params, true);
                    return value;
                };

                getters.filterValueGetter = (params) => {
                    const value = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);

                    if (value) {
                        return value.map(item => item.fileName).join(', ');
                    } else {
                        if (this.isNotStartTaskFieldForInputTask(field.fieldDefinition.id, taskDefinition.id, params.data)) {
                            return NOT_START;
                        } else {
                            return EMPTY_VALUE;
                        }
                    }
                };
            }

            result = {
                colId: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
                field: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
                columnType: field.fieldDefinition.type,
                headerName: field.fieldDefinition.name,
                tooltipValueGetter: getters.tooltipGetter,
                valueGetter: getters.valueGetter,
                maxWidth: 500,
                minWidht: 85,
                enableRowGroup: false,
                cellRenderer: cellComponent[field.fieldDefinition.type],
                cellRendererParams: {
                    fieldDefinitionId: field.fieldDefinition.id,
                    taskDefinitionId: taskDefinition.id
                },
                filterParams: {
                    valueGetter: getters.filterValueGetter
                }
            };
        } else if (field.fieldDefinition.type === FieldType.NUMBER) {
            if (!getters) {
                getters = { tooltipGetter: null, valueGetter: null, filterValueGetter: null };
                // extract the field config from the control on the template so that the value can be formatted
                const extractFieldConfig = (taskTemplate: any, fldId: string) => {
                    if (taskTemplate && taskTemplate.contains && taskTemplate.contains.length > 0) {
                        const rows = Array.from(taskTemplate.contains);
                        for (let r = 0; r < rows.length; r++) {
                            const row = Array.from(<any>rows[r]);
                            if (row && row.length > 0) {
                                const targetCtrl: any = row.find((cell: any) => cell.fieldDefinitionId === fldId);
                                if (targetCtrl) {
                                    return targetCtrl;
                                }
                            }
                        }
                    }
                    return null;
                };
                getters.valueGetter = (params) => {
                    const value = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);
                    return value;
                };

                getters.tooltipGetter = (params) => {
                    params[NOT_FORMAT_LINK_PARA_NAME] = true;
                    const value = this.cellRenderer(field.fieldDefinition, params, true);
                    return value;
                };

                getters.filterValueGetter = (params) => {
                    params[NOT_FORMAT_LINK_PARA_NAME] = true;
                    // step 1: obtain the raw value.
                    const sourceValue = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);
                    // step 2: attach the configuration to the field definition
                    field.fieldDefinition.configuration = extractFieldConfig(taskDefTemplate, field.fieldDefinition.id);
                    // step 3: format the numer value per the configuration.
                    const filterValue = this.cellRenderer(field.fieldDefinition, { value: sourceValue });
                    if (this.isNotStartTaskFieldForInputTask(field.fieldDefinition.id, taskDefinition.id, params.data)) {
                        return NOT_START;
                    }
                    return filterValue;
                };
            }

            result = {
                colId: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
                field: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
                headerName: field.fieldDefinition.name,
                tooltipValueGetter: getters.tooltipGetter,
                valueGetter: getters.valueGetter,
                maxWidth: 500,
                minWidht: 85,
                enableRowGroup: false,
                // put the original field type on the column definition.
                fieldType: FieldType.NUMBER,
                cellRenderer: cellComponent[field.fieldDefinition.type],
                cellRendererParams: {
                    fieldDefinitionId: field.fieldDefinition.id,
                    taskDefinitionId: taskDefinition.id
                },
                filterParams: {
                    valueGetter: getters.filterValueGetter
                }
            };

            result[NUMBER_FORMAT_ATTRIB] = this.getFormatForNumber(field, taskDefTemplate);
        }
        return result;
    }

    generateColDefForRichEditor(field: Field, taskDefinition: TaskDefinition): any {
        const result: any = {
            colId: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
            field: taskDefinition.id + COLUMN_ID_DELIMITER + field.fieldDefinition.id,
            headerName: field.fieldDefinition.name,
            columnType: field.fieldDefinition.type,
            columnConfig: field,
            minWidht: 85,
            filter: false, // Per Pdm's comments, disable filtering on rich editor columns.
            suppressSorting: true,
            valueGetter: (params) => {
                const value = this.valueGetterFromInstanceForField(field.fieldDefinition.id, taskDefinition.id, params.data);
                return value;
            },
            cellRenderer: (params) => {
                const imageElement = document.createElement('img');
                imageElement.width = 13;
                imageElement.height = 13;
                if (params && params.value) {
                    if (params.value) {
                        imageElement.src = './assets/images/singlesvg/note.svg';
                        return imageElement;
                    } else {
                        return '';
                    }
                } else {
                    if (this.isNotStartTaskFieldForInputTask(field.fieldDefinition.id, taskDefinition.id, params.data)) {
                        return NOT_START_TASK_FIELD_STYLE;
                    }
                }
            }
        };
        return result;
    }

    generateColDefForTaskByField(field: Field): any {
        const result: any = {
            colId: field.fieldDefinition.id,
            // field: 'value',
            headerName: field.fieldDefinition.name,
            // sortingOrder: field.fieldDefinition.type === FieldType.DATE ? ['desc', 'asc', ''] : ['asc', 'desc', ''],
            tooltipValueGetter: (params) => {
                params[NOT_FORMAT_LINK_PARA_NAME] = true;
                const value = this.cellRenderer(field.fieldDefinition, params, true);
                return value;
            },
            valueGetter: (params) => {
                const value = this.valueGetterFromTask(field.fieldDefinition.id, params.data);
                return value;
            },
            cellRenderer: (params) => {
                const value = this.cellRenderer(field.fieldDefinition, params);
                return value;
            },
            hide: true, // hide for now, TPMs have not figured out requirements
            maxWidth: 500,
        };
        if (field.fieldDefinition.type === FieldType.NUMBER || field.fieldDefinition.type === FieldType.CURRENCY) {
            result.cellStyle = { textAlign: 'right' };
            result.enableValue = true;
            result.aggFunc = 'sum';
            result.allowedAggFuncs = ['sum', 'min', 'max'];
            result.enableRowGroup = false;
        }
        if (field.fieldDefinition.type === FieldType.ENTITY || field.fieldDefinition.type === FieldType.User) {
            result.enableRowGroup = true;
        }
        if (field.fieldDefinition.type === FieldType.DATE) {
            result.enableRowGroup = true;
            result.filterParams = {
                valueGetter: (params) => {
                    const value = this.valueGetterFromTask(field.fieldDefinition.id, params.data);
                    return value === '' ? BLANK_VALUE : value;
                },
                cellRenderer: (params) => {
                    const value = this.cellRenderer(field.fieldDefinition, params);
                    return value;
                }
            };
        }
        return result;
    }

    /**
     * get control value from instance by field id
     * @param fieldId the field id
     * @param instance the instance
     * @returns the control value
     */
    getControlValueFromInstanceByFieldId(fieldId: string, instance: ProcessInstance): any {
        let controlValue;
        if (instance) {
            for (const task of instance.task) {
                const field: Field = task.fields.get(fieldId);
                if (field && (field.value || field.value === 0)) {
                    controlValue = field.value;
                    break;
                }
            }
        }
        return controlValue;
    }

    /**
     * obtains the font color for the stage of the given task definition.
     *
     * @param {TaskDefinition} task the given task definition.
     * @return {*} the stage color string.
     * @memberof WorkflowService
     */
    getTaskStageFontColor(task: TaskDefinition) {
        const stageColorIndex = !!task.stageColor ? task.stageColor : 0;
        return this.translateHex(COLORS[this.setStageColorIndex(stageColorIndex)]);
    }

    /**
     * obtains the background for the stage of the given task definition.
     *
     * @param {TaskDefinition} task the given task definition.
     * @return {*} the stage background.
     * @memberof WorkflowService
     */
    getTaskStageBackground(task: TaskDefinition) {
        const stageColorIndex = !!task.stageColor ? task.stageColor : 0;
        return WorkflowService.convertStageForeColorToBackground(COLORS[this.setStageColorIndex(stageColorIndex)]);
    }

    generateInstanceGridColumnDef(processDefinition: ProcessDefinition, cellComponent: Object): Array<any> {
        const _colors = _lodash.cloneDeep(COLORS);
        const result: Array<any> = [
            {
                headerName: 'Project Name',
                valueGetter: (params) => {
                    const instance: ProcessInstance = params.data;
                    if (instance) {
                        return instance.projectName;
                        // return this.getInstanceProjectName(instance, processDefinition);
                    }
                    return instance;
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    return (params.value);
                },
                colId: DefaultColumnID.PROJECT_NAME,
                field: 'project-name',
                enableRowGroup: false,
                type: 'instance',
            },
            {
                headerName: 'Stage',
                valueGetter: (params) => {
                    const instance: ProcessInstance = params.data;
                    if (instance) {
                        if (instance.status === ProcessInstanceStatus.IN_PROCESS) {
                            return instance.stage ? instance.stage.name : BLANK_VALUE;
                        } else {
                            return instance.statusValue || '';
                        }
                    }
                    return instance || '';
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    // set the color of stage column in project Board page to be the same as stage tag in Edit Workflow page
                    const instance: ProcessInstance = params.data;
                    let stageColorIndex = 0;
                    let value;
                    let fontColor = '';
                    let background = '';
                    if (instance && instance.stage) {
                        const taskDefinistions = processDefinition.taskDefinition;
                        for (const index in taskDefinistions) {
                            if (taskDefinistions[index].stage.name === instance.stage.name) {
                                stageColorIndex = !taskDefinistions[index].stageColor ? 0 : taskDefinistions[index].stageColor;
                                break;
                            }
                        }
                        if (instance.status === ProcessInstanceStatus.IN_PROCESS) {
                            value = instance.stage ? instance.stage.name : BLANK_VALUE;
                            fontColor = this.translateHex(_colors[this.setStageColorIndex(stageColorIndex)]);
                            // opacity 15%  = Hex +26
                            background = WorkflowService.convertStageForeColorToBackground(_colors[this.setStageColorIndex(stageColorIndex)]);
                        } else if (instance.status === ProcessInstanceStatus.DONE) {
                            value = instance.statusValue || '';
                            fontColor = DONE_COLOR;
                            // opacity 15%  = Hex +26
                            background = WorkflowService.convertStageForeColorToBackground(DONE_COLOR);

                        } else if (instance.status === ProcessInstanceStatus.REJECTED) {
                            value = instance.statusValue || '';
                            fontColor = REJECT_COLOR;
                            // opacity 15%  = Hex +26
                            background = WorkflowService.convertStageForeColorToBackground(REJECT_COLOR);
                        }
                        return `<span style = 'background-color:${background};color:${fontColor};padding:0 6px 0 6px;border-radius: 2.5px;'> ${value}</span>`;
                    }
                },
                colId: DefaultColumnID.STAGE,
                field: 'stage',
                enableRowGroup: true,
                type: 'instance',
            },
            {
                headerName: 'Created Date',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        return task.startDate || '';
                    }
                    return task || '';
                },
                tooltipValueGetter: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MM/dd/yyyy HH:mm') : null;
                },
                cellRenderer: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MM/dd/yyyy HH:mm') : null;
                },
                filterParams: {
                    valueGetter: (params) => {
                        const task: Task = params.data;
                        if (task) {
                            return task.startDate || '';
                        }
                        return task || '';
                    },
                    cellRenderer: (params) => {
                        if (!isNaN(Date.parse(params.value))) {
                            return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MM/dd/yyyy HH:mm') : null;
                        }
                        return params.value;
                    },
                },
                colId: DefaultColumnID.CREATED_DATE,
                field: 'start-date',
                columnType: FieldType.DATE,
                enableRowGroup: true,
                type: 'instance',
                hide: true,
            },
            {
                headerName: 'Originator',
                valueGetter: (params) => {
                    const instance: ProcessInstance = params.data;
                    if (instance && instance.originator) {
                        return instance.originator.name;
                    }
                    return instance;
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    return (params.value);
                },
                colId: DefaultColumnID.ORIGINATOR,
                field: 'originator',
                enableRowGroup: true,
                type: 'instance',
                hide: true,
            },
        ];
        processDefinition.taskDefinition.forEach(taskDef => {
            if (taskDef.type === TaskType.INPUT) {
                // some configuration for fields only can be found on template.
                const taskDefTemplate = processDefinition.template.find(taskTemplate => taskTemplate.id === taskDef.id);
                taskDef.fields.forEach(field => {
                    let oneColDef: any;
                    switch (field.fieldDefinition.type) {
                        case FieldType.ENTITY:
                        case FieldType.User:
                        case FieldType.CURRENCY:
                        case FieldType.TEXT:
                        case FieldType.DATE:
                        case FieldType.TEXT_AREA:
                        case FieldType.CHECK_LIST:
                            oneColDef = this.generateColDefForInstanceByField(field, taskDef);
                            break;
                        case FieldType.NUMBER:
                        case FieldType.FILE:
                            oneColDef = this.generateColDefForInstanceSpecialField(field, taskDef, cellComponent, taskDefTemplate);
                            break;
                        case FieldType.RICH_EDITOR:
                            oneColDef = this.generateColDefForRichEditor(field, taskDef);
                            oneColDef.sortable = false;
                            break;
                        default:
                            break;
                    }

                    if (oneColDef) {
                        result.push(oneColDef);
                    }
                });
            } else if (taskDef.type === TaskType.APPROVAL) {
                const oneColDef: any = this.generateColDefForInstanceByApproveTaskDefinition(taskDef);
                if (oneColDef) {
                    result.push(oneColDef);
                }
            }
        });
        return result;
    }

    /**
     * generates the column definitions for the grid in the task view
     *
     * @param {Array<TaskDefinition>} taskDefinition an array of task definitions
     * @param {ProcessDefinition} processDefinition a process definitons
     * @param {*} [extraCellClassMap=new Map<string, string>()] an optional argument, used to append extra class to specific columns.
     * @return {*}  {Array<any>}
     * @memberof WorkflowService
     */
    generateTaskGridColumnDef(taskDefinition: Array<TaskDefinition>, processDefinition: ProcessDefinition, extraCellClassMap = new Map<string, string>()): Array<any> {
        const result: Array<any> = [
            {
                headerName: 'Project Id',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        return task.processInstance.id || '';
                    }
                    return task || '';
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    return params.value;
                },
                colId: PROJECT_ID_COLUMN_ID,
                field: PROJECT_ID_COLUMN_ID,
                enableRowGroup: true,
                rowGroup: true,
                rowGroupIndex: 0,
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                headerName: 'Task Name',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        return task.name;
                    }
                    return task;
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    return (params.value);
                },
                colId: 'task-name',
                field: 'task-name',
                enableRowGroup: false,
            },
            {
                headerName: 'Project Name',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        // group row value must type string, if is undefined, the group will not work.
                        return task.processInstance.projectName || '';
                        // return this.getInstanceProjectName(task.processInstance, processDefinition);
                    }
                    return task || '';
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    return params.value;
                },
                colId: 'project-name',
                field: 'project-name',
                enableRowGroup: false,
            },
            {
                headerName: 'Stage',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        return task.taskDefinition.stage ? task.taskDefinition.stage.name : '';
                    }
                    return task || '';
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    const _colors = _lodash.cloneDeep(COLORS);
                    // set the color of stage column in task Board page to be the same as stage tag in Edit Workflow page
                    let stageColorIndex;
                    stageColorIndex = !params.data.taskDefinition.stageColor ? 0 : params.data.taskDefinition.stageColor;

                    const fontColor = this.translateHex(_colors[this.setStageColorIndex(stageColorIndex)]);
                    const background = WorkflowService.convertStageForeColorToBackground(_colors[this.setStageColorIndex(stageColorIndex)]);
                    // const stageName = params.data.taskDefinition.stage.name;
                    const task: Task = params.data;
                    let value = '';
                    if (task) {
                        value = task.taskDefinition.stage ? task.taskDefinition.stage.name : '';
                    } else {
                        value = '';
                    }
                    // add a special class in order to get Click-Outside Directive ignore the click on this cell.
                    const classSegment = extraCellClassMap.has(TaskDefinition.getStageColumnKey()) ? `class='${extraCellClassMap.get(TaskDefinition.getStageColumnKey())}''` : '';
                    return `<span ${classSegment} style = 'background-color:${background};color:${fontColor};padding:0 6px 0 6px; border-radius: 2.5px;'> ${value}</span>`;
                },
                colId: 'stage',
                field: 'stage',
                enableRowGroup: false,
            },
            {
                headerName: 'Status',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        return this.getDisplayValueForTaskStatus(task) || '';
                    }
                    return task || '';
                },
                tooltipValueGetter: (params) => {
                    return (params.value);
                },
                cellRenderer: (params) => {
                    return (params.value);
                },
                colId: 'status',
                field: 'status',
                enableRowGroup: false,
            },
            {
                headerName: 'Assignee',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        let assignees = [];
                        if (task.owner) {
                            assignees = [task.owner];
                        } else {
                            assignees = task.displayAssignee.filter(ass => ass.name);
                            ArrayHelperService.sort(assignees, 'shortName');
                        }
                        return assignees.map(item => item.shortName).join('; ');
                    }
                    return '';
                },
                tooltipValueGetter: (params) => {
                    return params.value;
                },
                colId: 'assignee',
                field: 'assignee',
                enableRowGroup: false,
            },
            {
                headerName: 'Owner',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task && task.owner) {
                        return task.owner.shortName;
                    }
                    return '';
                },
                tooltipValueGetter: (params) => {
                    return params.value;
                },
                colId: 'owner',
                field: 'owner',
                enableRowGroup: false,
            },
            {
                headerName: 'Due Date',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        if (task.dueDate) {
                            // for group
                            const value = DateHelperWebService.parseDateFunc(task.dueDate).getTime();
                            return value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(value), 'MMM-dd-yyyy') : null;
                        }
                        return '';
                    }
                    return '';
                },
                tooltipValueGetter: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MMM-dd-yyyy') : null;
                },
                cellRenderer: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MMM-dd-yyyy') : null;
                },
                colId: 'due-date',
                field: 'due-date',
                columnType: FieldType.DATE,
                enableRowGroup: false,
                hide: true,
                filter: 'customGridColumnFilter'
            },
            {
                headerName: 'Created Date',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        if (task.startDate) {
                            const value = DateHelperWebService.parseDateFunc(task.startDate).getTime();
                            // for group
                            return value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(value), 'MMM-dd-yyyy') : null;
                        }
                        return '';
                    }
                    return '';
                },
                tooltipValueGetter: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MMM-dd-yyyy') : null;
                },
                cellRenderer: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MMM-dd-yyyy') : null;
                },
                colId: 'start-date',
                field: 'start-date',
                columnType: FieldType.DATE,
                enableRowGroup: false,
                filter: 'customGridColumnFilter'
            },
            {
                headerName: 'Completed Date',
                valueGetter: (params) => {
                    const task: Task = params.data;
                    if (task) {
                        if (task.endDate) {
                            // for group
                            const value = DateHelperWebService.parseDateFunc(task.endDate).getTime();
                            return value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(value), 'MMM-dd-yyyy') : null;
                        }
                        return '';
                    }
                    return '';
                },
                tooltipValueGetter: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MMM-dd-yyyy') : BLANK_VALUE;
                },
                cellRenderer: (params) => {
                    return params.value ? DateHelperWebService.getDateString(DateHelperWebService.parseDateFunc(params.value), 'MMM-dd-yyyy') : BLANK_VALUE;
                },
                colId: 'end-date',
                field: 'end-date',
                columnType: FieldType.DATE,
                enableRowGroup: false,
                hide: true,
                filter: 'customGridColumnFilter'
            },
        ];

        return result;
    }

    getAbbrNameForOneEntity(assignee: EntityBrief): string {
        if (assignee.type.id === EntityType.TEAM.id) {
            const oriName: string = assignee.shortName.trim();
            if (oriName.length > 2) {
                return oriName.slice(0, 2).toUpperCase();
            } else {
                return oriName.toUpperCase();
            }
        } else {
            const oriName: string = assignee.name.trim();
            if (oriName.indexOf(' ') > -1) {
                const tempNames: Array<string> = oriName.split(' ');
                let result = '';
                for (let i = 0; i < tempNames.length && i < 4; i++) {
                    const oneTemp: string = tempNames[i].trim();
                    if (oneTemp) {
                        result = result + oneTemp[0].toUpperCase();
                    }
                }
                return result;
            } else if (oriName.length > 2) {
                return oriName.slice(0, 2).toUpperCase();
            } else {
                return oriName.toUpperCase();
            }
        }
    }

    getAllWorkflowFieldDefinition() {
        const url = `${AppConfig.workFlowFieldEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const options = {
            headers: headers,
        };
        return this._transportService.get(url, options);
    }

    /**
     * get automation card content
     * @param autoItem automation item
     * @returns
     */
    getAutomationCardContent(autoItem: AutomationModelBase, noteTypes: Array<EntryType>) {
        switch (autoItem.objectType) {
            case 'entry': { // note
                const entryType = noteTypes.find(nt => nt.id === autoItem.objectTypeID);
                if (autoItem.action === 'create') {
                    return `${StringLiteralsPipe.translate('workflow.create_a_note')} - ${entryType ? entryType.name : ''}`;
                } else {
                    return `${StringLiteralsPipe.translate('workflow.update_a_note')} - ${entryType ? entryType.name : ''}`;
                }
            }
            case 'sideEntry': { // sidenote
                const entryType = noteTypes.find(nt => nt.id === autoItem.objectTypeID);
                if (autoItem.action === 'create') {
                    return `${StringLiteralsPipe.translate('workflow.create_a_sidenote')} - ${entryType ? entryType.name : ''}`;
                } else {
                    return `${StringLiteralsPipe.translate('workflow.update_a_sidenote')}`;
                }
            }
            case 'relationship': { // relationship
                return `${StringLiteralsPipe.translate('workflow.create_relationships')}`;
            }
            default:
                throw new Error('unsupported object type!');
        }
    }

    getDisplayFieldsOnCard(taskDefinition: TaskDefinition): Array<Field> {
        let displayFields: Array<Field> = [];
        const fields = Array.from(taskDefinition.fields.values());
        displayFields = fields.filter(field => field.fieldDefinition.type !== FieldType.RICH_EDITOR);
        return displayFields;
    }

    getDisplayValueForEntities(entities: Array<EntityBrief>): string {
        return entities ? entities.map(item => item.shortName).join('; ') : '';
    }

    getDisplayValueForFile(fileInfo: Array<FileInfo>): string {
        let result = '';
        if (fileInfo && fileInfo.length) {
            result = fileInfo.map(item => item.fileName).join(',&nbsp;');
        }
        return result;
    }

    getDisplayValueForInstanceStatus(instance: ProcessInstance): string {
        let result: string;
        switch (instance.status) {
            case ProcessInstanceStatus.IN_PROCESS:
                result = 'In Progress';
                break;
            case ProcessInstanceStatus.DONE:
                result = 'Done';
                break;
            case ProcessInstanceStatus.REJECTED:
                result = 'Rejected';
                break;
            default:
                result = '';
                break;
        }
        return result;
    }

    getDisplayValueForTaskStatus(task: Task): string {
        let result = '';
        switch (task.status) {
            case TaskStatus.TODO:
                if (task.taskDefinition.type === TaskType.APPROVAL) {
                    result = StringLiteralsPipe.translate('workflow.task_status_pending');
                } else {
                    result = StringLiteralsPipe.translate('workflow.task_status_to_do');
                }
                break;
            case TaskStatus.IN_PROGRESS:
                result = StringLiteralsPipe.translate('workflow.task_status_in_progress');
                break;
            case TaskStatus.DONE:
                if (task.taskDefinition.type === TaskType.APPROVAL) {
                    result = StringLiteralsPipe.translate('workflow.task_status_approved');
                } else {
                    result = StringLiteralsPipe.translate('workflow.task_status_done');
                }
                break;
            case TaskStatus.REJECTED:
                result = StringLiteralsPipe.translate('workflow.task_status_rejected');
                break;
            default:
                break;
        }
        return result;
    }

    getDueOrEndDateFromInstance(instance: ProcessInstance): Date {
        let currentTask = instance.task.find(item => item.taskDefinition && (item.status === TaskStatus.TODO || item.status === TaskStatus.IN_PROGRESS));
        if (!currentTask) {
            currentTask = instance.task[instance.task.length - 1];
        }
        // TODO below need server change, instance will have due date
        return currentTask.status === TaskStatus.IN_PROGRESS ? currentTask.dueDate : (currentTask.status === TaskStatus.TODO ? null : currentTask.endDate);
    }

    getDueOrEndDateFromTask(task: Task): Date {
        return task.status === TaskStatus.IN_PROGRESS ? task.dueDate : (task.status === TaskStatus.TODO ? null : task.endDate);
    }

    getEndTaskNodeIdAfterOneExclusiveLine(currentTaskDefinitionId: string, processDefinition: ProcessDefinition): string {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
        let gatewayEndNode: string;
        if (gatewayDefinition) {
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextStep.targetRef, processDefinition);
                return this.getEndTaskNodeIdAfterOneExclusiveLine(gatewayEndNode, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextStep.targetRef, processDefinition);
                return this.getEndTaskNodeIdAfterOneExclusiveLine(gatewayEndNode, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.OR) {
                return nextStep.targetRef;
            } else {
                console.error('Configuration error, "and" can not be at end of exclusive');
            }
            return gatewayEndNode;
        } else {
            if (nextStep) {
                return this.getEndTaskNodeIdAfterOneExclusiveLine(nextStep.targetRef, processDefinition);
            } else {
                return gatewayEndNode;
            }
        }
    }

    getEndTaskNodeIdAtEndOfOneExclusiveLineOrTarget(currentTaskDefinitionId: string, targetTaskDefinitionId: string, processDefinition: ProcessDefinition): string {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
        const nextStepsCount = nextSteps.length;
        let gatewayEndNode: string = null;
        if (gatewayDefinition) {
            if (currentTaskDefinitionId === targetTaskDefinitionId) {
                return currentTaskDefinitionId;
            } else {
                if (gatewayDefinition.type === GatewayType.PARALLEL) {
                    for (let i = 0; i < nextStepsCount; i++) {
                        const nextStep: ConnectionLine = nextSteps[i];
                        const oneGatewayEndNode = this.getEndTaskNodeIdAtEndOfOneParallelLineOrTarget(nextStep.targetRef, targetTaskDefinitionId, processDefinition);
                        if (oneGatewayEndNode === targetTaskDefinitionId) {
                            gatewayEndNode = oneGatewayEndNode;
                            break;
                        } else {
                            gatewayEndNode = oneGatewayEndNode;
                        }
                    }
                    if (gatewayEndNode === targetTaskDefinitionId) {
                        return gatewayEndNode;
                    } else if (gatewayEndNode !== null) {
                        const tempNext: ConnectionLine = lines.find(item => item.sourceRef === gatewayEndNode);
                        if (tempNext) {
                            return this.getEndTaskNodeIdAtEndOfOneExclusiveLineOrTarget(tempNext.targetRef, targetTaskDefinitionId, processDefinition);
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                    for (let i = 0; i < nextStepsCount; i++) {
                        const nextStep: ConnectionLine = nextSteps[i];
                        const oneGatewayEndNode = this.getEndTaskNodeIdAtEndOfOneExclusiveLineOrTarget(nextStep.targetRef, targetTaskDefinitionId, processDefinition);
                        if (oneGatewayEndNode === targetTaskDefinitionId) {
                            gatewayEndNode = oneGatewayEndNode;
                            break;
                        } else {
                            gatewayEndNode = oneGatewayEndNode;
                        }
                    }
                    if (gatewayEndNode === targetTaskDefinitionId) {
                        return gatewayEndNode;
                    } else if (gatewayEndNode !== null) {
                        const tempNext: ConnectionLine = lines.find(item => item.sourceRef === gatewayEndNode);
                        if (tempNext) {
                            return this.getEndTaskNodeIdAtEndOfOneExclusiveLineOrTarget(tempNext.targetRef, targetTaskDefinitionId, processDefinition);
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                } else if (gatewayDefinition.type === GatewayType.OR) {
                    return currentTaskDefinitionId;
                } else {
                    console.error('Configuration error, "and" can not be at end of exclusive');
                    return null;
                }
            }
        } else {
            if (currentTaskDefinitionId === targetTaskDefinitionId) {
                return currentTaskDefinitionId;
            } else {
                if (nextSteps && nextStepsCount === 1) {
                    return this.getEndTaskNodeIdAtEndOfOneExclusiveLineOrTarget(nextSteps[0].targetRef, targetTaskDefinitionId, processDefinition);
                } else {
                    return null;
                }
            }
        }
    }

    getEndTaskNodeIdAfterOneParallelLine(currentTaskDefinitionId: string, processDefinition: ProcessDefinition): string {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
        let gatewayEndNode: string;
        if (gatewayDefinition) {
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextStep.targetRef, processDefinition);
                return this.getEndTaskNodeIdAfterOneParallelLine(gatewayEndNode, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextStep.targetRef, processDefinition);
                return this.getEndTaskNodeIdAfterOneParallelLine(gatewayEndNode, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.AND) {
                if (nextStep) {
                    return nextStep.targetRef;
                }
            } else {
                console.error('Configuration error, "or" can not be at end of parallel');
            }
            return gatewayEndNode;
        } else {
            if (nextStep) {
                return this.getEndTaskNodeIdAfterOneParallelLine(nextStep.targetRef, processDefinition);
            } else {
                return gatewayEndNode;
            }
        }
    }

    /**
     * Gets the id of the nearest end node of a gateway.
     * @param currentTaskDefinitionId the source node, from which query the nearest end node of a gateway.
     * @param targetTaskDefinitionId the target node id.
     * @param processDefinition the workflow definition
     * @returns the end node id of the gateway.
     */
    getEndTaskNodeIdAtEndOfOneParallelLineOrTarget(currentTaskDefinitionId: string, targetTaskDefinitionId: string, processDefinition: ProcessDefinition): string {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        // if the target node is a gateway.
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        // find the lines begin with the given target node id.
        const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
        const nextStepsCount = nextSteps.length;
        let gatewayEndNode: string = null;
        if (gatewayDefinition) {
            // found the gateway definition. the given target node is a gateway
            if (currentTaskDefinitionId === targetTaskDefinitionId) {
                // the given target node id is equal to the target task definition id.
                return currentTaskDefinitionId;
            } else {
                switch (gatewayDefinition.type) {
                    case GatewayType.PARALLEL:
                    case GatewayType.EXCLUSIVE:
                    case GatewayType.CONDITIONAL: {
                        for (let i = 0; i < nextStepsCount; i++) {
                            const nextStep: ConnectionLine = nextSteps[i];
                            const oneGatewayEndNode = this.getEndTaskNodeIdAtEndOfOneParallelLineOrTarget(nextStep.targetRef, targetTaskDefinitionId, processDefinition);
                            if (oneGatewayEndNode === targetTaskDefinitionId) {
                                gatewayEndNode = oneGatewayEndNode;
                                break;
                            } else {
                                gatewayEndNode = oneGatewayEndNode;
                            }
                        }
                        if (gatewayEndNode === targetTaskDefinitionId) {
                            return gatewayEndNode;
                        } else if (gatewayEndNode !== null) {
                            const tempNext: ConnectionLine = lines.find(item => item.sourceRef === gatewayEndNode);
                            if (tempNext) {
                                return this.getEndTaskNodeIdAtEndOfOneParallelLineOrTarget(tempNext.targetRef, targetTaskDefinitionId, processDefinition);
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                    }
                    case GatewayType.AND:
                    case GatewayType.SKIP: // current id is an end node of a gateway, return it.
                        return currentTaskDefinitionId;
                    default:
                        console.error('Configuration error, "or" can not be at end of parallel');
                        return null;
                }
            }
        } else {
            if (currentTaskDefinitionId === targetTaskDefinitionId) {
                return currentTaskDefinitionId;
            } else {
                if (nextSteps && nextStepsCount === 1) {
                    return this.getEndTaskNodeIdAtEndOfOneParallelLineOrTarget(nextSteps[0].targetRef, targetTaskDefinitionId, processDefinition);
                } else {
                    return null;
                }
            }
        }
    }

    // TODO need clear requirements
    getEntitiesFromInstance(instance: ProcessInstance): string {
        const inputTasks: Array<Task> = instance.task.filter(item => item.taskDefinition && item.taskDefinition.type === TaskType.INPUT);
        const fields: Array<Field> = [];
        if (inputTasks.length > 0) {
            inputTasks.forEach(inputTask => {
                if (inputTask.fields.size > 0) {
                    fields.push(...Array.from(inputTask.fields.values()));
                }
            });
            if (fields.length > 0) {
                return this.getDisplayValueForEntities(this.getEntitiesFromFields(fields));
            }
        }
        return '';
    }

    getEntitiesFromFields(fields: Array<Field>): Array<EntityBrief> {
        const result: Array<EntityBrief> = [];
        const tempIdSet: Set<string> = new Set();
        fields.forEach(f => {
            if (f.fieldDefinition.type === FieldType.ENTITY && f.value && f.value.length) {
                f.value.forEach((v: EntityBrief) => {
                    if (!tempIdSet.has(v.id)) {
                        tempIdSet.add(v.id);
                        result.push(v);
                    }
                });
            }
        });
        ArrayHelperService.sort(result, 'shortName');
        return result;
    }

    // TODO need clear requirements
    getEntitiesFromTask(task: Task): string {
        if (task.taskDefinition.type === TaskType.INPUT) {
            const entityField: Field = Array.from(task.taskDefinition.fields.values()).find(item => item.fieldDefinition.type === FieldType.ENTITY);
            if (entityField) {
                return this.getDisplayValueForEntities(entityField.value);
            }
        }
        return '';
    }

    getExportWorkflowJsonById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workFlowProcessDefinitionEndpoint}/export/${id}/`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }

    getFollowingNodeNumberInNormalLineToEnd(currentTaskDefinitionId: string, processDefinition: ProcessDefinition, countedTaskNodeIds: Array<string>, countAllIntoArray: boolean): number {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        if (gatewayDefinition) {
            const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
            let gatewayNodeCount = 0;
            let gatewayEndNode: string;
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                nextSteps.forEach(item => {
                    gatewayNodeCount += this.getFollowingNodeNumberInOneParallelLine(item.targetRef, processDefinition, countedTaskNodeIds, countAllIntoArray);
                });
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                let tempCountedTaskNodeIds: Array<string> = [];
                nextSteps.forEach(item => {
                    const oneTempCountedTaskNodeIds: Array<string> = [];
                    const oneCount = this.getFollowingNodeNumberInOneExclusiveLine(item.targetRef, processDefinition, oneTempCountedTaskNodeIds, countAllIntoArray);
                    if (oneCount > gatewayNodeCount) {
                        gatewayNodeCount = oneCount;
                        if (!countAllIntoArray) {
                            tempCountedTaskNodeIds = oneTempCountedTaskNodeIds;
                        }
                    }
                    if (countAllIntoArray) {
                        tempCountedTaskNodeIds.push(...oneTempCountedTaskNodeIds);
                    }
                });
                countedTaskNodeIds.push(...tempCountedTaskNodeIds);
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextSteps[0].targetRef, processDefinition);
            } else if (nextSteps.length) {
                gatewayEndNode = nextSteps[0].targetRef;
            }
            if (gatewayEndNode) {
                return gatewayNodeCount + this.getFollowingNodeNumberInNormalLineToEnd(gatewayEndNode, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return gatewayNodeCount;
            }
        } else {
            const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
            if (currentTaskDefinitionId) {
                countedTaskNodeIds.push(currentTaskDefinitionId);
            }
            if (nextStep) {
                return 1 + this.getFollowingNodeNumberInNormalLineToEnd(nextStep.targetRef, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return currentTaskDefinitionId ? 1 : 0;
            }
        }
    }

    getFollowingNodeNumberInOneExclusiveLine(currentTaskDefinitionId: string, processDefinition: ProcessDefinition, countedTaskNodeIds: Array<string>, countAllIntoArray: boolean): number {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        if (gatewayDefinition) {
            const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
            let gatewayNodeCount = 0;
            let gatewayEndNode: string;
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                nextSteps.forEach(item => {
                    gatewayNodeCount += this.getFollowingNodeNumberInOneParallelLine(item.targetRef, processDefinition, countedTaskNodeIds, countAllIntoArray);
                });
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                let tempCountedTaskNodeIds: Array<string> = [];
                nextSteps.forEach(item => {
                    const oneTempCountedTaskNodeIds: Array<string> = [];
                    const oneCount = this.getFollowingNodeNumberInOneExclusiveLine(item.targetRef, processDefinition, oneTempCountedTaskNodeIds, countAllIntoArray);
                    if (oneCount > gatewayNodeCount) {
                        gatewayNodeCount = oneCount;
                        if (!countAllIntoArray) {
                            tempCountedTaskNodeIds = oneTempCountedTaskNodeIds;
                        }
                    }
                    if (countAllIntoArray) {
                        tempCountedTaskNodeIds.push(...oneTempCountedTaskNodeIds);
                    }
                });
                countedTaskNodeIds.push(...tempCountedTaskNodeIds);
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.OR) {
                gatewayNodeCount = 0;
            } else {
                console.error('Logic error, "and" can not exist at end of exclusive');
            }
            if (gatewayEndNode) {
                return gatewayNodeCount + this.getFollowingNodeNumberInOneExclusiveLine(gatewayEndNode, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return gatewayNodeCount;
            }
        } else {
            const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
            countedTaskNodeIds.push(currentTaskDefinitionId);
            if (nextStep) {
                return 1 + this.getFollowingNodeNumberInOneExclusiveLine(nextStep.targetRef, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return 1;
            }
        }
    }

    getFollowingNodeNumberInOneParallelLine(currentTaskDefinitionId: string, processDefinition: ProcessDefinition, countedTaskNodeIds: Array<string>, countAllIntoArray: boolean): number {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        if (gatewayDefinition) {
            const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
            let gatewayNodeCount = 0;
            let gatewayEndNode: string;
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                nextSteps.forEach(item => {
                    gatewayNodeCount += this.getFollowingNodeNumberInOneParallelLine(item.targetRef, processDefinition, countedTaskNodeIds, countAllIntoArray);
                });
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                let tempCountedTaskNodeIds: Array<string> = [];
                nextSteps.forEach(item => {
                    const oneTempCountedTaskNodeIds: Array<string> = [];
                    const oneCount = this.getFollowingNodeNumberInOneExclusiveLine(item.targetRef, processDefinition, oneTempCountedTaskNodeIds, countAllIntoArray);
                    if (oneCount > gatewayNodeCount) {
                        gatewayNodeCount = oneCount;
                        if (!countAllIntoArray) {
                            tempCountedTaskNodeIds = oneTempCountedTaskNodeIds;
                        }
                    }
                    if (countAllIntoArray) {
                        tempCountedTaskNodeIds.push(...oneTempCountedTaskNodeIds);
                    }
                });
                countedTaskNodeIds.push(...tempCountedTaskNodeIds);
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.AND) {
                gatewayNodeCount = 0;
            } else {
                console.error('Logic error, "or" can not exist at end of parallel');
            }
            if (gatewayEndNode) {
                return gatewayNodeCount + this.getFollowingNodeNumberInOneParallelLine(gatewayEndNode, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return gatewayNodeCount;
            }
        } else {
            const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
            countedTaskNodeIds.push(currentTaskDefinitionId);
            if (nextStep) {
                return 1 + this.getFollowingNodeNumberInOneParallelLine(nextStep.targetRef, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return 1;
            }
        }
    }

    /**
     * gets the configured format for a given number field.
     * find the format further on the passed template if no found on field definition.
     *
     * @param {Field} field The given number field.
     * @param {*} [taskDefTemplate] The template of the given number field.
     * @return {*} The format to display the number field.
     * @memberof WorkflowService
     */
    getFormatForNumber(field: Field, taskDefTemplate?: any) {
        let format: any;
        if (field.fieldDefinition.configuration) {
            format = WorkflowService.getNumberFormatBasedOnConfig(<any>field.fieldDefinition.configuration);
        } else if (!!taskDefTemplate && taskDefTemplate.contains) {
            taskDefTemplate.contains.forEach(row => {
                row.forEach(control => {
                    if (control.type === ControlType.NUMBER && field && field.id === control.fieldDefinitionId) {
                        format = WorkflowService.getNumberFormatBasedOnConfig(control);
                    }
                });
            });
        } else {
            format = WorkflowService.getNumberFormatBasedOnConfig(null);
        }
        return format;
    }

    getIdFromServerInStore(_store): string {
        let id = '';
        let ids = '';
        _store.pipe(
            select(workflowIdsSelector),
            take(1)
        ).subscribe(data => ids = data);

        if (ids.length <= 10) {
            _store.dispatch({
                type: WorkflowActionTypes.GET_IDS
            });
        }
        id = ids[0];

        _store.dispatch({
            type: WorkflowActionTypes.DELETE_ONE_ID
        });
        return id;
    }

    getIds(number: number = 100) {
        const url = `${AppConfig.workFlowEndpoint}guid`;

        const headers = {
            'Content-Type': 'application/json'
        };
        const params = {
            'number': number,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getImprotWorkflowJsonByConfiguration(workflowConfig): Observable<any> {
        const url = `${AppConfig.workFlowProcessDefinitionEndpoint}import`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(workflowConfig));
        return this._transportService.post(url, formData);
    }

    getInstanceProjectName(instance: ProcessInstance, processDefinition: ProcessDefinition): string {
        let result = '';
        result = result + instance.projectName;
        return result;
    }

    getNodeNumberInNormalLineToTarget(currentTaskDefinitionId: string, targetTaskDefinitionId: string, processDefinition: ProcessDefinition, countedTaskNodeIds: Array<string>, countAllIntoArray: boolean): number {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        if (gatewayDefinition) {
            if (currentTaskDefinitionId === targetTaskDefinitionId) {
                return 0;
            } else {
                const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
                let gatewayNodeCount: number = null;
                let gatewayEndNode: string;
                if (gatewayDefinition.type === GatewayType.PARALLEL) {
                    nextSteps.forEach(item => {
                        const oneParallelCount: number = this.getNodeNumberInOneParallelLineToTarget(item.targetRef, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
                        if (oneParallelCount) {
                            gatewayNodeCount += oneParallelCount;
                        }
                    });
                    gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextSteps[0].targetRef, processDefinition);
                } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                    let tempCountedTaskNodeIds: Array<string> = [];
                    nextSteps.forEach(item => {
                        const oneTempCountedTaskNodeIds: Array<string> = [];
                        const oneCount = this.getNodeNumberInOneExclusiveLineToTarget(item.targetRef, targetTaskDefinitionId, processDefinition, oneTempCountedTaskNodeIds, countAllIntoArray);
                        if (oneCount > gatewayNodeCount) {
                            gatewayNodeCount = oneCount;
                            if (!countAllIntoArray) {
                                tempCountedTaskNodeIds = oneTempCountedTaskNodeIds;
                            }
                        }
                        if (countAllIntoArray) {
                            tempCountedTaskNodeIds.push(...oneTempCountedTaskNodeIds);
                        }
                    });
                    countedTaskNodeIds.push(...tempCountedTaskNodeIds);
                    gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextSteps[0].targetRef, processDefinition);
                } else {
                    gatewayEndNode = nextSteps[0].targetRef;
                }
                if (gatewayEndNode) {
                    return gatewayNodeCount + this.getNodeNumberInNormalLineToTarget(gatewayEndNode, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
                } else {
                    return gatewayNodeCount;
                }
            }
        } else {
            if (currentTaskDefinitionId === targetTaskDefinitionId) {
                return 1;
            } else {
                const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
                if (nextStep) {
                    const nextStepCount: number = this.getNodeNumberInNormalLineToTarget(nextStep.targetRef, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
                    if (nextStepCount === null) {
                        return null;
                    } else {
                        countedTaskNodeIds.push(currentTaskDefinitionId);
                        return 1 + nextStepCount;
                    }
                } else {
                    return null;
                }
            }
        }
    }

    getNodeNumberInOneExclusiveLineToTarget(currentTaskDefinitionId: string, targetTaskDefinitionId: string, processDefinition: ProcessDefinition, countedTaskNodeIds: Array<string>, countAllIntoArray: boolean): number {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        if (gatewayDefinition) {
            const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
            let gatewayNodeCount = 0;
            let gatewayEndNode: string;
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                nextSteps.forEach(item => {
                    gatewayNodeCount += this.getNodeNumberInOneParallelLineToTarget(item.targetRef, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
                });
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                let tempCountedTaskNodeIds: Array<string> = [];
                nextSteps.forEach(item => {
                    const oneTempCountedTaskNodeIds: Array<string> = [];
                    const oneCount = this.getNodeNumberInOneExclusiveLineToTarget(item.targetRef, targetTaskDefinitionId, processDefinition, oneTempCountedTaskNodeIds, countAllIntoArray);
                    if (oneCount > gatewayNodeCount) {
                        gatewayNodeCount = oneCount;
                        if (!countAllIntoArray) {
                            tempCountedTaskNodeIds = oneTempCountedTaskNodeIds;
                        }
                    }
                    if (countAllIntoArray) {
                        tempCountedTaskNodeIds.push(...oneTempCountedTaskNodeIds);
                    }
                });
                countedTaskNodeIds.push(...tempCountedTaskNodeIds);
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.OR) {
                gatewayNodeCount = 0;
            } else {
                console.error('Logic error, "and" can not exist at end of exclusive');
            }
            if (gatewayEndNode) {
                return gatewayNodeCount + this.getNodeNumberInOneExclusiveLineToTarget(gatewayEndNode, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return gatewayNodeCount;
            }
        } else {
            const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
            countedTaskNodeIds.push(currentTaskDefinitionId);
            if (nextStep) {
                return 1 + this.getNodeNumberInOneExclusiveLineToTarget(nextStep.targetRef, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return 1;
            }
        }
    }

    getNodeNumberInOneParallelLineToTarget(currentTaskDefinitionId: string, targetTaskDefinitionId: string, processDefinition: ProcessDefinition, countedTaskNodeIds: Array<string>, countAllIntoArray: boolean): number {
        const lines: Array<ConnectionLine> = processDefinition.configuration.connectionLine;
        const gatewayDefinition: GatewayDefinition = processDefinition.gatewayDefinition.find(item => item.id === currentTaskDefinitionId);
        if (gatewayDefinition) {
            const nextSteps: Array<ConnectionLine> = lines.filter(item => item.sourceRef === currentTaskDefinitionId);
            let gatewayNodeCount = 0;
            let gatewayEndNode: string;
            if (gatewayDefinition.type === GatewayType.PARALLEL) {
                nextSteps.forEach(item => {
                    gatewayNodeCount += this.getNodeNumberInOneParallelLineToTarget(item.targetRef, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
                });
                gatewayEndNode = this.getEndTaskNodeIdAfterOneParallelLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.EXCLUSIVE) {
                let tempCountedTaskNodeIds: Array<string> = [];
                nextSteps.forEach(item => {
                    const oneTempCountedTaskNodeIds: Array<string> = [];
                    const oneCount = this.getNodeNumberInOneExclusiveLineToTarget(item.targetRef, targetTaskDefinitionId, processDefinition, oneTempCountedTaskNodeIds, countAllIntoArray);
                    if (oneCount > gatewayNodeCount) {
                        gatewayNodeCount = oneCount;
                        if (!countAllIntoArray) {
                            tempCountedTaskNodeIds = oneTempCountedTaskNodeIds;
                        }
                    }
                    if (countAllIntoArray) {
                        tempCountedTaskNodeIds.push(...oneTempCountedTaskNodeIds);
                    }
                });
                countedTaskNodeIds.push(...tempCountedTaskNodeIds);
                gatewayEndNode = this.getEndTaskNodeIdAfterOneExclusiveLine(nextSteps[0].targetRef, processDefinition);
            } else if (gatewayDefinition.type === GatewayType.AND) {
                gatewayNodeCount = 0;
            } else {
                console.error('Logic error, "or" can not exist at end of parallel');
            }
            if (gatewayEndNode) {
                return gatewayNodeCount + this.getNodeNumberInOneParallelLineToTarget(gatewayEndNode, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return gatewayNodeCount;
            }
        } else {
            const nextStep: ConnectionLine = lines.find(item => item.sourceRef === currentTaskDefinitionId);
            countedTaskNodeIds.push(currentTaskDefinitionId);
            if (nextStep) {
                return 1 + this.getNodeNumberInOneParallelLineToTarget(nextStep.targetRef, targetTaskDefinitionId, processDefinition, countedTaskNodeIds, countAllIntoArray);
            } else {
                return 1;
            }
        }
    }

    // TODO need clear requirements
    getNumberFromInstance(instance: ProcessInstance): number {
        const inputTasks: Array<Task> = instance.task.filter(item => item.taskDefinition && item.taskDefinition.type === TaskType.INPUT);
        const fields: Array<Field> = [];
        if (inputTasks.length > 0) {
            inputTasks.forEach(inputTask => {
                if (inputTask.fields.size > 0) {
                    const numberFields: Array<Field> = Array.from(inputTask.taskDefinition.fields.values()).filter(item => item.fieldDefinition.type === FieldType.NUMBER || item.fieldDefinition.type === FieldType.CURRENCY);
                    if (numberFields.length > 0) {
                        fields.push(...numberFields);
                    }
                }
            });
            const validFields: Array<Field> = fields.filter(item => item.value !== null && item.value !== undefined);
            if (validFields.length > 0) {
                return validFields[0].value;
            } else if (fields.length > 0) {
                return fields[0].value;
            }
        }
        return null;
    }

    // TODO need clear requirements
    getNumberFromTask(task: Task): number {
        if (task.taskDefinition.type === TaskType.INPUT) {
            const numberField: Field = Array.from(task.taskDefinition.fields.values()).find(item => item.fieldDefinition.type === FieldType.NUMBER || item.fieldDefinition.type === FieldType.CURRENCY);
            if (numberField) {
                return numberField.value;
            }
        }
        return null;
    }

    getPreviouTasksByTaskStatus(instance: ProcessInstance, briefConfig, fields, currentTask: Task): Array<Task> {
        const previousTasks: Array<Task> = [];
        const tempTasks = instance.task.filter(item => item.status === TaskStatus.DONE && item.taskDefinition);
        const tasks = _lodash.cloneDeep(tempTasks);

        tasks.forEach(task => {
            if (task.taskDefinition.template) {
                task.taskDefinition.template.contains.forEach(row => {
                    row.forEach(control => {
                        let completedField = task.fields.get(control.fieldDefinitionId);
                        if (currentTask.currentApprovalTask) {
                            completedField = task.completedFields.get(control.fieldDefinitionId);
                        }
                        const oneConfig = task.fields.get(control.fieldDefinitionId);
                        if (completedField) {
                            control.field = completedField;
                        } else if (oneConfig) {
                            control.field = oneConfig;
                        } else {
                            control.field = fields.get(control.fieldDefinitionId);
                        }
                        control.disabled = false;
                        control.editable = false;
                        control.config = briefConfig;
                        if (control.type === ControlType.NUMBER && !control.field.fieldDefinition.configuration) {
                            control.field.fieldDefinition.configuration = control;
                        }
                    });
                });
            }
            previousTasks.push(task);
        });
        return previousTasks;
    }

    getPreviousTasksByTaskIdAndProcess(taskId: string, instance: ProcessInstance, definition: ProcessDefinition, briefConfig, fields): Array<Task> {
        const previousTasks: Array<Task> = [];
        const sortedNodeIdArray: Array<string> = this.getSortedNodeIdArrayByProcessDefinition(definition);
        const sortedTaskDefinitionList: Array<TaskDefinition> = this.getSortedTaskDefinitionListBySortedIds(sortedNodeIdArray, definition.taskDefinition);
        const allTasks: Array<Task> = instance.task.filter(item => item.taskDefinition);
        const currentTask: Task = allTasks.find(item => item.id === taskId);
        const sortedTaskList: Array<Task> = this.sortTask(allTasks, sortedNodeIdArray);
        const groupedTaskList: Array<Array<Task>> = this.getGroupedTaskList(sortedTaskDefinitionList, sortedTaskList, false);
        const tempLength = groupedTaskList.length;

        for (let i = 0; i < tempLength; i++) {
            const oneGroup: Array<Task> = groupedTaskList[i];
            let oneTask: Task = oneGroup.pop();
            if (oneTask && oneTask.taskDefinition && oneTask.taskDefinition.id === currentTask.taskDefinition.id && oneTask.id === taskId) {
                // In case having callback/sendback, former task will not include current task but include current task's last time's task
                oneTask = oneGroup.pop();
            }
            if (oneTask) {
                if (oneTask.taskDefinition.template) {
                    oneTask.taskDefinition.template.contains.forEach(row => {
                        row.forEach(control => {
                            const oneConfig = oneTask.fields.get(control.fieldDefinitionId);
                            if (oneConfig) {
                                control.field = oneConfig;
                            } else {
                                control.field = fields.get(control.fieldDefinitionId);
                            }
                            control.editable = false;
                            control.config = briefConfig;
                            if (control.type === ControlType.NUMBER && !control.field.fieldDefinition.configuration) {
                                control.field.fieldDefinition.configuration = control;
                            }
                        });
                    });
                }
                previousTasks.push(oneTask);
            }
        }
        if (instance.processInstanceEvent.length && instance.processInstanceEvent[0].targetTaskDefinition && currentTask.taskDefinition.id === instance.processInstanceEvent[0].targetTaskDefinition.id) {
            const tempTask: any = instance.processInstanceEvent[0].detail.find(item => item.id === instance.processInstanceEvent[0].triggerTaskId);
            if (tempTask) {
                if (tempTask.taskDefinition.template) {
                    tempTask.taskDefinition.template.contains.forEach(row => {
                        row.forEach(control => {
                            const oneConfig = tempTask.fields.get(control.fieldDefinitionId);
                            if (oneConfig) {
                                control.field = oneConfig;
                            } else {
                                control.field = fields.get(control.fieldDefinitionId);
                            }
                            control.editable = false;
                            control.config = briefConfig;
                            if (control.type === ControlType.NUMBER && !control.field.fieldDefinition.configuration) {
                                control.field.fieldDefinition.configuration = control;
                            }
                        });
                    });
                }
                previousTasks.push(tempTask);
            }
        }
        return previousTasks;
    }

    getGroupedInstanceList(sortedGroupList: Array<TaskDefinition | Stage | { id: ProcessInstanceStatus, name: string }>, instanceList: Array<ProcessInstance>, groupByStage: boolean, groupInProgress: boolean): Array<Array<ProcessInstance>> {
        instanceList.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
        const _groupedInstanceList: Array<Array<ProcessInstance>> = [];
        if (groupByStage) { // Group by stage
            if (groupInProgress) {
                sortedGroupList.forEach(group => {
                    _groupedInstanceList.push(instanceList.filter(ins => ins.stage && ins.stage.id === group.id && ins.status === ProcessInstanceStatus.IN_PROCESS));
                });
            } else {
                sortedGroupList.forEach(group => {
                    _groupedInstanceList.push(instanceList.filter(ins => ins.stage && ins.stage.id === group.id));
                });
            }
        } else { // Group by task definition
            instanceList.forEach(item => item.task.sort((t1, t2) => t1.startDate.getTime() - t2.startDate.getTime()));
            if (groupInProgress) {
                sortedGroupList.forEach(group => {
                    // TODO, fix bug TAM-32116, may confirm in the future
                    const tempIns = [];
                    instanceList.forEach(ins => {
                        if (ins.status === ProcessInstanceStatus.IN_PROCESS) {
                            if (ins.task.find(item => item.taskDefinition && item.taskDefinition.id === group.id && (item.status === TaskStatus.TODO || item.status === TaskStatus.IN_PROGRESS))) {
                                tempIns.push(ins);
                            }
                        }
                    });
                    _groupedInstanceList.push(tempIns);
                });
            } else {
                sortedGroupList.forEach(group => {
                    _groupedInstanceList.push(instanceList.filter(ins => ins.task[ins.task.length - 1].taskDefinition && ins.task[ins.task.length - 1].taskDefinition.id === group.id));
                });
            }
        }
        if (groupInProgress) {
            let tempInstanceList = instanceList.filter(ins => ins.status === ProcessInstanceStatus.DONE);
            const extraGroupedInstance: Map<string, Array<ProcessInstance>> = new Map();
            const extraGroups: Array<{ id: ProcessInstanceStatus, name: string }> = [];
            tempInstanceList.forEach(one => {
                let oneGroupedInstance: Array<ProcessInstance>;
                if (extraGroupedInstance.has(one.statusValue)) {
                    oneGroupedInstance = extraGroupedInstance.get(one.statusValue);
                    oneGroupedInstance.push(one);
                } else {
                    oneGroupedInstance = [one];
                    extraGroups.push({ id: ProcessInstanceStatus.DONE, name: one.statusValue });
                }
                extraGroupedInstance.set(one.statusValue, oneGroupedInstance);
            });
            tempInstanceList = instanceList.filter(ins => ins.status === ProcessInstanceStatus.REJECTED);
            tempInstanceList.forEach(one => {
                let oneGroupedInstance: Array<ProcessInstance>;
                if (extraGroupedInstance.has(one.statusValue)) {
                    oneGroupedInstance = extraGroupedInstance.get(one.statusValue);
                    oneGroupedInstance.push(one);
                } else {
                    oneGroupedInstance = [one];
                    extraGroups.push({ id: ProcessInstanceStatus.REJECTED, name: one.statusValue });
                }
                extraGroupedInstance.set(one.statusValue, oneGroupedInstance);
            });
            extraGroups.forEach(group => {
                sortedGroupList.push(group);
                _groupedInstanceList.push(extraGroupedInstance.get(group.name));
            });
        }
        return _groupedInstanceList;
    }

    getGroupedTaskList(sortedGroupList: Array<TaskDefinition | Stage>, sortedTaskList: Array<Task>, groupByStage: boolean): Array<Array<Task>> {
        const _groupedTaskList: Array<Array<Task>> = [];
        if (groupByStage) { // Group by stage
            sortedGroupList.forEach(group => {
                _groupedTaskList.push(sortedTaskList.filter(task => task.taskDefinition.stage.id === group.id));
            });
        } else { // Group by task definition
            sortedGroupList.forEach(group => {
                _groupedTaskList.push(sortedTaskList.filter(task => task.taskDefinition.id === group.id));
            });
        }
        return _groupedTaskList;
    }

    getProcessDefinitionById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workFlowProcessDefinitionEndpoint}${id}/`;
        const params = {
            expand: 'process-instance;task;task-definition;stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getProcessDefinitionList(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.workFlowProcessDefinitionEndpoint;
        const params = {
            expand: 'process-instance;task;task-definition;stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getProcessDefinitionListSummary(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.workFlowProcessDefinitionEndpoint;
        const params = {};
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getProcessInstanceList(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.workFlowProcessInstanceEndpoint;
        const params = {
            expand: 'process-definition;task;task-definition;stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getProcessInstanceById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workFlowProcessInstanceEndpoint}${id}/`;
        const params = {
            expand: 'process-definition;task;task-definition;stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getSortedNodeIdArrayByProcessDefinition(selectedProcessDef: ProcessDefinition): Array<string> {
        const sortedNodeIdArray: Set<string> = new Set();
        const connectionLine = selectedProcessDef.configuration.connectionLine;
        const sourceRef: Set<string> = new Set();
        sourceRef.add(selectedProcessDef.configuration.startTask);
        sortedNodeIdArray.add(selectedProcessDef.configuration.startTask);
        // collect all lines begin with the start task node.
        let linesFromSrcRef: Array<ConnectionLine> = connectionLine.filter(item => sourceRef.has(item.sourceRef));
        // TODO: circular connection detection need to be added.
        while (linesFromSrcRef && linesFromSrcRef.length) {
            sourceRef.clear();
            linesFromSrcRef.forEach(oneNext => {
                const oneTargerId = oneNext.targetRef;
                sourceRef.add(oneTargerId);
                if (sortedNodeIdArray.has(oneTargerId)) {
                    sortedNodeIdArray.delete(oneTargerId);
                }
                if (this.isTaskDefinitionIdOrGatewayId(oneTargerId, selectedProcessDef)) {
                    sortedNodeIdArray.add(oneTargerId);
                }
            });
            linesFromSrcRef = connectionLine.filter(item => sourceRef.has(item.sourceRef));
        }
        return Array.from(sortedNodeIdArray);
    }

    /**
     * Gets a flag indicating whether should group by stages and works out an array of stages for grouping.
     * @param sortedStageList an out paramenter includes stages from both existing task definitions and deleted task definitions.
     * @param sortedTaskDefinitionList an array of existing task definitions.
     * @param deletedTaskDefList an array of deleted task definitions.
     * @returns a flag indicating whether should group by stages.
     */
    getSortedStageList(sortedStageList: Array<Stage>, sortedTaskDefinitionList: Array<TaskDefinition>, deletedTaskDefList?: Array<TaskDefinition>): boolean {
        let tempShowStage = true;
        const taskDefs = [...sortedTaskDefinitionList];
        if (deletedTaskDefList && deletedTaskDefList.length > 0) {
            taskDefs.push(...deletedTaskDefList);
        }

        taskDefs.forEach(def => {
            if (def.stage) {
                if (sortedStageList.findIndex(stage => stage.id === def.stage.id) < 0) {
                    sortedStageList.push(def.stage);
                }
            } else {
                tempShowStage = false;
            }
        });
        return tempShowStage;
    }

    getSortedTaskDefinitionListBySortedIds(sortedNodeIdArray: Array<string>, allTaskDefinitionList: Array<TaskDefinition>): Array<TaskDefinition> {
        const sortedTaskDefinitionList: Array<TaskDefinition> = [];
        sortedNodeIdArray.forEach(id => {
            const tempIndex = allTaskDefinitionList.findIndex(item => item.id === id);
            if (tempIndex > -1) {
                sortedTaskDefinitionList.push(allTaskDefinitionList[tempIndex]);
            }
        });
        return sortedTaskDefinitionList;
    }

    getTaskCountInInstance(instance: ProcessInstance): number {
        const finishedTasksDefinitionIds: Set<string> = new Set();
        instance.task.forEach(item => {
            if (item.taskDefinition) {
                finishedTasksDefinitionIds.add(item.taskDefinition.id);
            }
        });
        return finishedTasksDefinitionIds.size;
    }

    getTaskDefinitionTemplateByTaskDefinitionId(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workFlowTaskDefinitionTemplateEndpoint}${id}/`;
        const params = {
            expand: 'field',
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.get(url, options);
    }

    /**
     * Used to judge whether the field which belongs to input task is started or not
     * @param fieldId
     * @param taskDefinitionId
     * @param instance
     * @returns
     */
    isNotStartTaskFieldForInputTask(fieldId: string, taskDefinitionId: string, instance: ProcessInstance): boolean {
        let isNotStartTaskField = false;
        if (instance && instance.task) {
            const task: Task = instance.task.find(item => item.taskDefinition && item.taskDefinition.id === taskDefinitionId && (item.fields.has(fieldId) || item.taskDefinition.fields.has(fieldId)));
            if (task) {
                const taskStatus = task.status;
                if (taskStatus === TaskStatus.TODO) {
                    isNotStartTaskField = true;
                }
            } else {
                isNotStartTaskField = true;
            }
        }
        return isNotStartTaskField;
    }

    /**
     * Used to judge whether the field which belongs to approve task is started or not
     * @param taskDefinitionId
     * @param instance
     * @returns
     */
    isNotStartTaskFieldForForApproveTask(taskDefinitionId: string, instance: ProcessInstance): boolean {
        let isNotStartTaskField = false;
        if (instance) {
            const tasks: Array<Task> = instance.task.filter(item => item.taskDefinition && item.taskDefinition.type === TaskType.APPROVAL && item.taskDefinition.id === taskDefinitionId);
            if (tasks && tasks.length) {
                const taskStatus = tasks[0].status;
                if (taskStatus === TaskStatus.TODO) {
                    isNotStartTaskField = true;
                }
            } else {
                isNotStartTaskField = true;
            }
        }
        return isNotStartTaskField;
    }

    // workflow configuration is legal
    isWorkflowJsonFormat(workflowConfig): boolean {
        try {
            let processDefinition = new ProcessDefinition();
            processDefinition = ProcessDefinition.parse(workflowConfig);

            if (workflowConfig.version &&
                workflowConfig['export-version'] &&
                processDefinition.id &&
                processDefinition.name &&
                processDefinition.createDate &&
                processDefinition.configuration &&
                processDefinition.configuration.triggerCondition &&
                processDefinition.configuration.connectionLine &&
                processDefinition.configuration.startTask &&
                processDefinition.taskDefinition &&
                processDefinition.taskDefinition.length > 0 &&
                processDefinition.template &&
                processDefinition.template.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    /**
     * @param id
     * @param processDefinition
     * true: taskDefinition, false: gateway, null: exception
     */
    isTaskDefinitionIdOrGatewayId(id: string, processDefinition: ProcessDefinition): boolean {
        if (processDefinition.taskDefinition.find(def => def.id === id)) {
            return true;
        } else if (processDefinition.gatewayDefinition.find(item => item.id === id)) {
            return false;
        } else {
            return null;
        }
    }

    /**
     * determine whether the given task has a subsequent conditional gateway.
     * @param id the given task definition id
     * @param processDefinition the process definition.
     */
    isTaskFollowByConditionalGateway(id: string, processDefinition: ProcessDefinition): boolean {
        if (!!id && !!processDefinition && !!processDefinition.configuration && !!processDefinition.configuration.connectionLine && !!processDefinition.gatewayDefinition) {
            const matchedLine = processDefinition.configuration.connectionLine.find(line => line.sourceRef === id && processDefinition.gatewayDefinition.findIndex(gateway => gateway.id === line.targetRef && gateway.type === GatewayType.CONDITIONAL) >= 0);

            return !!matchedLine;
        }

        return false;
    }

    saveTaskDefinitionInBatch(config) {
        const url = `${AppConfig.workFlowTaskDefinitionBatchEndpoint}`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(config));
        const params = {
            expand: 'stage;field',
        };
        const options = {
            params: params,
        };
        return this._transportService.post(url, formData, options);
    }

    sortTask(sortedTaskList: Array<Task>, sortedNodeIdArray: Array<string>): Array<Task> {
        sortedTaskList.sort((task1, task2) => {
            let result = sortedNodeIdArray.indexOf(task1.taskDefinition.id) - sortedNodeIdArray.indexOf(task2.taskDefinition.id);
            if (result === 0) {
                result = task2.startDate.getTime() - task1.startDate.getTime();
            }
            return result;
        });
        return sortedTaskList;
    }

    startNewProcessInstance(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowProcessDefinitionEndpoint}startprocess/`;
        const params = {
            'process-definition-id': id,
            expand: 'process-definition;task;task-definition;stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, null, options);
    }

    setStageColorIndex(stageColorIndex) {
        const _colors = _lodash.cloneDeep(COLORS);
        let colorIndex = parseInt(stageColorIndex, 0);
        if (colorIndex >= 0) {
            if (colorIndex >= _colors.length) {
                return colorIndex = colorIndex % _colors.length;
            }
            return colorIndex;
        }
    }

    translateHex(hex) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        // tslint:disable-next-line: no-shadowed-variable
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const r = parseInt(result[1], 16);
        const g = parseInt(result[2], 16);
        const b = parseInt(result[3], 16);
        return 'rgb(' +
            Math.round(r * 0.7) + ',' + Math.round(g * 0.7) + ',' + Math.round(b * 0.7) + ')';
    }

    updateTaskDefinitionTemplate(id: string, template: any): Observable<any> {
        const url = `${AppConfig.workFlowTaskDefinitionTemplateEndpoint}${id}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(template));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    setAdminVisibility(visible = false) {
        const url = AppConfig.workflowAdminSettingEndPoint;
        const formData: FormData = new FormData();
        formData.append('enable', String(visible));
        return this._transportService.put(url, formData, {});
    }

    valueGetterFromInstanceForApproveTask(taskDefinitionId: string, instance: ProcessInstance): any {
        if (instance) {
            const tasks: Array<Task> = instance.task.filter(item => item.taskDefinition && item.taskDefinition.type === TaskType.APPROVAL && item.taskDefinition.id === taskDefinitionId);
            if (tasks && tasks.length) {
                tasks.sort((task1, task2) => task2.startDate.getTime() - task1.startDate.getTime());
                if (!ProcessInstance.isNotRejectedOngoingTask(instance, tasks[0])) {
                    // Do NOT show the status of ongoing tasks of rejected projects in the cell.
                    return null;
                } else {
                    return this.getDisplayValueForTaskStatus(tasks[0]);
                }
            } else {
                return null;
            }
        }
        return instance;
    }

    valueGetterFromInstanceForField(fieldId: string, taskDefinitionId: string, instance: ProcessInstance): any {
        if (instance && instance.task) {
            const task: Task = instance.task.find(item => item.taskDefinition && item.taskDefinition.id === taskDefinitionId && (item.fields.has(fieldId) || item.taskDefinition.fields.has(fieldId)));
            if (task) {
                const field: Field = task.fields.get(fieldId);
                if (field && (field.value || field.value === 0)) {
                    if (field.fieldDefinition.type === FieldType.DATE) {
                        return DateHelperWebService.formatEntireDate(field.value, 1);
                    } else {
                        return field.value;
                    }
                } else {
                    return '';
                }
            } else {
                return null;
            }
        }
        return instance;
    }

    updateWorkflowBrief(data): Observable<any> {
        const url = `${AppConfig.screenEndpoint}${data.id}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const formData = new URLSearchParams();
        // https://jiraprod.advent.com/browse/TAM-37230
        // A workflow config does not need the attribute - href.
        // This attribute is appended when fetching back as a screen.
        delete data.href;
        formData.set('screen', JSON.stringify(data));
        formData.set('showpermission', 'true');
        const params = {
            expand: 'entity',
            showpermission: true,
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    valueGetterFromTask(fieldId: string, task: Task): any {
        if (task) {
            const field: Field = task.fields.get(fieldId);
            if (field && (field.value || field.value === 0)) {
                if (field.fieldDefinition.type === FieldType.ENTITY || field.fieldDefinition.type === FieldType.User) {
                    return this.getDisplayValueForEntities(field.value);
                } else if (field.fieldDefinition.type === FieldType.DATE) {
                    return DateHelperWebService.formatEntireDate(field.value, 1);
                } else {
                    return field.value;
                }
            } else if (task.taskDefinition && task.taskDefinition.fields.has(fieldId)) {
                return '';
            } else {
                return null;
            }
        }
        return task;
    }


    // get task info by id
    getFieldInfoById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workFlowFieldEndpoint}${id}/`;
        const params = {
            expand: 'field',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * attaches the extra configuration from the template field to the task definition.
     * currently, only apply the attaching to the numeric field.
     *
     * @param {Task} task the given task
     * @memberof WorkflowService
     */
    attachFieldConfigFromTemplate(task: Task) {
        if (task.taskDefinition && task.taskDefinition.template && task.taskDefinition.template.contains) {
            task.taskDefinition.template.contains.forEach(row => {
                row.forEach(control => {
                    const taskField = task.fields.get(control.fieldDefinitionId);
                    if (control.type === ControlType.NUMBER && taskField && taskField.fieldDefinition && !taskField.fieldDefinition.configuration) {
                        taskField.fieldDefinition.configuration = control;
                    }
                });
            });
        }
    }

    /**
     * set columns auto size by the grid width and total columns width.
     *
     * @memberof WorkflowService
     */
    setGridAutoSizeByWidth(gridWidth, gridApi, columnApi): void {
        if (this._getDisplayColumnsWidth(columnApi) < gridWidth) {
            // size columns to fit when the total columns width < the grid width
            gridApi.sizeColumnsToFit();
        } else {
            // auto size all columns when the totla columns width >= the grid width
            columnApi.autoSizeAllColumns();
        }
    }

    getProjectHistory(projectID: string): Observable<ProjectHistory> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workflowProjectHistoryEndPoint}${projectID}`;
        const params = {
            fields: 'template;field-definition',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options).map(res => ProjectHistory.parse(res)).share();
    }

    // get total columns width
    private _getDisplayColumnsWidth(columnApi): number {
        let displayColumnsWidth = 0;
        columnApi.getAllDisplayedColumns().forEach((col) => {
            displayColumnsWidth = displayColumnsWidth + col.getActualWidth();
        });
        return displayColumnsWidth;
    }

    /**
     * set grid column key creator
     * @param params the grid parameter
     * @param checkLists the check list items
     * @param field current field
     * @returns 
     */
    private _setGridColumnKeyCreator(params, checkLists, field) {
        if (params && params.value) {
            field['value'] = params.value;
            return WorkflowService.getFieldBriefContent(field, checkLists);
        } else {
            return BLANK_VALUE;
        }
    }
}
