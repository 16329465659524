import { Subject } from 'rxjs';

export class ContextMenuItem {
    id?: string;
    text?: string;
    divider?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    icon?: string;
    checked?: boolean;
    iconColor?: string;
}

export class ContextMenuConfig {
    items: Array<any>;
    show$: Subject<any>;
    events$: Subject<any>;
    single = false;
    showItemIcon = false;
    stateful = true;
    // tslint:disable-next-line: max-line-length
    clickOnDiv = true; // In HTML, the 3rd ngx-context-menu provide interface (execute)="clickFunction()", but 3rd library has bug, the clickFunction may not work sometime. So we add (click)="clickFunction()" on <div>, but sometime both will work which will cause click 2 times. Use clickOnDiv to control enable click through (execute) or (click)
    forceRefresh?: boolean; // When config.items changed, but HTML will not change before popup show, need to force refresh. This is only used for dynamic items
    eventKey?: string;
    eventValue?: any;

    constructor() {
        this.show$ = new Subject<any>();
        this.events$ = new Subject<any>();
    }
}

export class ContextMenuShowAction {
    event: MouseEvent;
    data: any;

    constructor(event: MouseEvent, data?: any) {
        this.event = event;
        this.data = data;
    }
}

export enum ContextMenuEventTypes {
    click,
    checkChanged,
}

export class ContextMenuEvent {
    type: ContextMenuEventTypes;
    payload: ContextMenuClickEvent | any;

    constructor(type: ContextMenuEventTypes, payload: any) {
        this.type = type;
        this.payload = payload;
    }
}

export class ContextMenuClickEvent {
    id: string;
    data: any;
    event: MouseEvent;
    menuItem?: ContextMenuItem;

    constructor(id: string, data: any, event: MouseEvent, menuItem?: ContextMenuItem) {
        this.id = id;
        this.data = data;
        this.event = event;
        this.menuItem = menuItem;
    }
}
