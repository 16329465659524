/**
 * Created by Daniel Wang on 5/6/2024.
 * Description:
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { FilterMetadataType, FiltersConfig } from '../../../../../tamalelibs/components/filter-config';
import { ALL_VALUE, CURRENT_USER } from '../../../../../tamalelibs/constants/business.constants';
import { MetadataType, NotificationFilterModel, RecipientModel, RecipientType } from '../../../../../tamalelibs/models/notification.model';
import { MapFilterService } from '../../../../../tamalelibs/services/map-filter.service';
import { StandardFilterItemsNotificationRule } from '../../../../../widgets/field-selector/field-selector.model';
import { EntityService } from '../../../../../tamalelibs/services/entity.service';
import { map } from 'rxjs/operators';
import { FilterPanelActions } from '../../../../../widgets/filter-item/filter-panel/filter-panel.view-model';
import { ALL_NAME } from './notification-editor.model';

@Injectable({
    providedIn: 'root'
})

export class NotificationEditorService {
    constructor(
        private _entityService: EntityService,
        private _mapFilterService: MapFilterService,
    ) {
    }

    /**
    * convert metadata filter to filterConfigList
    * @param metadataFilter
    * @returns
    */
    convertMetadataFilterToFilterConfigList(metadataFilter: NotificationFilterModel, dropdownSource, metadataType): Array<FiltersConfig> {
        const filterConfigList = new Array<FiltersConfig>();
        if (metadataFilter) {
            metadataFilter.filters[0]?.filters.forEach(filterItem => {
                let filterConfig = new FiltersConfig();

                // to handle the basic controls
                const regex = /^[0-9a-z]{32}$/; // to match 32 bit GUID
                if (filterItem.attrDefId.length === 1 && !regex.test(filterItem.attrDefId[0])) {
                    // set metadata type for filter item
                    if (!filterItem.metadataType) {
                        filterItem.metadataType = filterItem.attrDefId[0];
                    }
                    // build filter config for the item
                    filterConfig = this._mapFilterService.getNewFilterDefaultInstance(filterItem.name ? filterItem.name : filterItem.dataType, filterItem.metadataType);
                    // override the title by the default text if exists.
                    Object.values(StandardFilterItemsNotificationRule).forEach(item => {
                        if (item.value.toLowerCase() === filterItem.attrDefId[0].toLowerCase()) {
                            filterConfig.title = item.text;
                        }
                    });
                    // hidden close icon except entry type
                    filterConfig.isHiddenCloseIcon = false;
                    if (filterItem.attrDefId[0].toLowerCase() === 'entrytype') {
                        filterConfig.title = 'Entry type';
                        filterConfig.isShowTypeFilter = true;
                        filterConfig.isHiddenCloseIcon = true;
                        filterConfig.typeValue = filterItem['restrictType'];
                        if (!filterConfig.typeValue) {
                            if (metadataType === MetadataType.NOTE) {
                                filterConfig.typeValue = 'noteTypes';
                            } else if (metadataType === MetadataType.EVENT) {
                                filterConfig.typeValue = 'eventTypes';
                            } else {
                                filterConfig.typeValue = 'entryTypes';
                            }
                        }
                    }

                    // remove relationship from entites
                    if (filterConfig.metadataType.toLowerCase() === FilterMetadataType.Entities.toLowerCase()) {
                        filterConfig.isHiddenRelationship = true;
                    }
                    if (filterConfig.metadataType.toLowerCase() === FilterMetadataType.Priority.toLowerCase()) {
                        filterConfig.selectedOperator = filterItem.operator;
                    } else {
                        const matchedOpertator = filterConfig.operators.filter(item => item.name.toLowerCase() === filterItem.operator.toLowerCase());
                        if (matchedOpertator && matchedOpertator.length > 0) {
                            filterConfig.selectedOperator = matchedOpertator[0].value;
                        }
                    }
                    // set value for the filter config
                    this._setValueForFilterConfig(filterConfig, filterItem);
                } else { // to handle adhoc controls
                    if (!filterItem.metadataType) {
                        filterItem.metadataType = filterItem.controlType ? filterItem.controlType : filterItem.dataType;
                        if (!filterItem.metadataType || filterItem.metadataType.toLowerCase() === 'text') { // set metadata type as textbox if both controlType and dataType have no value.
                            filterItem.metadataType = FilterMetadataType.TextBox;
                        }
                    }
                    filterConfig = this._mapFilterService.getAdhocFilterDefaultInstance(filterItem);
                    const matchedOpertator = filterConfig.operators.filter(item => item.name.toLowerCase() === filterItem.operator.toLowerCase());
                    if (matchedOpertator && matchedOpertator.length > 0) {
                        filterConfig.selectedOperator = matchedOpertator[0].value;
                    }
                    filterConfig.isHiddenCloseIcon = false;
                    const supportedType = [FilterMetadataType.Dropdown.toLowerCase(), FilterMetadataType.MSD.toLowerCase(), FilterMetadataType.CheckBox.toLowerCase()];
                    if (supportedType.includes(filterConfig.metadataType.toLowerCase())) {
                        const dropdown = dropdownSource.filter(data => {
                            // sometimes, the filtered item may contain types of Date, so we need to filter again using data.controlType.
                            return filterConfig.fields.filter(field => data.attrDefId.includes(field) && supportedType.includes(data.controlType.toLowerCase()))[0];
                        });
                        if (dropdown && dropdown[0]) {
                            filterConfig['source'] = dropdown[0].source.map(value => ({
                                name: value,
                                value: value
                            }));
                        }
                    }
                    // set value for the filter config
                    this._setValueForFilterConfig(filterConfig, filterItem);
                }

                this._convertPropertiesForFilterConfig(filterItem, filterConfig);
                filterConfigList.push(filterConfig);
            });
        }
        return filterConfigList;
    }

    /**
     * convert recipients to fitler config
     * @param recipicents
     * @returns
     */
    convertRecipientsToFilterConfigList(recipicents: Array<RecipientModel>): Array<FiltersConfig> {
        const filterConfigList = new Array<FiltersConfig>();
        if (recipicents && recipicents.length > 0) {
            const recipientTypes = Array.from(new Set(recipicents.map(item => item.recipientType)));
            recipientTypes.forEach(recipientType => {
                const filtersConfig = this._buildRecipientFilterConfig(recipicents, recipientType);
                filterConfigList.push(filtersConfig);
            });
        }
        return filterConfigList;
    }

    /**
     * get entity information
     * @param filterRulesConfig
     */
    getNewEntityInfoForFilterConfig(filterRulesConfig, specificActionType) {
        const entityIds = [];
        const needUpdate = (item) => {
            return item.metadataType.toLowerCase() === FilterMetadataType.Entity.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.ED.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.MED.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.MET.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.Participants.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.Source.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.Submitter.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.Team.toLowerCase() ||
                item.metadataType.toLowerCase() === FilterMetadataType.Entities.toLowerCase();
        };
        filterRulesConfig.filtersConfigList.forEach(item => {
            if (needUpdate(item) && item.value) {
                item.value.forEach(val => {
                    if (val !== CURRENT_USER.id) {
                        entityIds.push(val);
                    }
                });
                const index = item.value.findIndex(data => data === CURRENT_USER.id);
                if (index !== -1) {
                    item.value[index] = CURRENT_USER;
                }
            }
        });
        if (entityIds.length !== 0) {
            this._entityService.getEntityListByIdsQuick(entityIds).pipe(
                map(response => this._entityService.mapEntityBriefList(response)),
                map(entities => {
                    const entityMap = new Map();
                    entities.forEach(element => entityMap.set(element.id, element));
                    return entityMap;
                })
            ).subscribe((entityMap => {
                filterRulesConfig.filtersConfigList.forEach(item => {
                    if (needUpdate(item) && item.value) {
                        item.value = item.value.map(element => {
                            if (entityMap.has(element)) {
                                return entityMap.get(element);
                            }
                            return element;
                        });
                        item.value = item.value.filter(value => !!value);
                    }
                });
                filterRulesConfig.action$.next({ type: FilterPanelActions.reloadFilter, payload: filterRulesConfig.filtersConfigList });
            }));
        }
    }

    /**
     * set value for recipients
     * @param filterRecipientsConfig
     * @param currNotification
     */
    setValueForRecipients(filterRecipientsConfig, currNotification) {
        filterRecipientsConfig.filtersConfigList.forEach(filterConfig => {
            if (filterConfig.metadataType === FilterMetadataType.SpecificContacts) {
                currNotification.configuration.recipients.push(
                    {
                        'recipientType': RecipientType.CONTACT,
                        'recipientId': filterConfig.value
                    }
                );
            } else if (filterConfig.metadataType === FilterMetadataType.SpecificUsers) {
                currNotification.configuration.recipients.push(
                    {
                        'recipientType': RecipientType.USER,
                        'recipientId': filterConfig.value
                    }
                );
            } else if (filterConfig.metadataType === FilterMetadataType.SpecificTeams) {
                currNotification.configuration.recipients.push(
                    {
                        'recipientType': RecipientType.TEAM,
                        'recipientId': filterConfig.value
                    }
                );
            } else if (filterConfig.metadataType === FilterMetadataType.CustomField) {
                // we should conbine the ids and pass them to server
                let recipientIds: string[] = [];
                if (filterConfig.value === ALL_NAME.toLowerCase() && filterConfig.source.length > 0) {
                    filterConfig.source.forEach(s => {
                        recipientIds = recipientIds.concat(s.id);
                    });
                } else if (filterConfig.value && filterConfig.value.length > 0) {
                    filterConfig.value.forEach(item => {
                        recipientIds = recipientIds.concat(item);
                    });
                }
                currNotification.configuration.recipients.push(
                    {
                        'recipientType': RecipientType.FROM_ENTRY_METADATA,
                        'recipientId': recipientIds
                    }
                );
            }
        });
    }

    /**
     * build filter config for recipient
     * @param recipicents
     * @param recipientType
     * @returns
     */
    private _buildRecipientFilterConfig(recipicents: Array<RecipientModel>, recipientType): FiltersConfig {
        const filterConfig = new FiltersConfig();
        const recipients = recipicents.filter(item => item.recipientType === recipientType);

        if (recipients && recipients.length > 0) {
            if (recipientType === RecipientType.FROM_ENTRY_METADATA) {
                filterConfig.metadataType = 'Custom field';
                filterConfig.dataType = 'custom field';
                filterConfig.fields = ['entryType'];
                filterConfig.operators = [{ name: 'Includes', value: 'Includes' }];
                filterConfig.selectedOperator = 'Includes';
                filterConfig.title = 'Custom field';
                filterConfig.value = recipients[0].recipientId;
                filterConfig.hideOperators = true;
            } else {
                filterConfig.metadataType = 'Specific ' + recipientType.toString() + 's';
                filterConfig.dataType = 'specific ' + recipientType.toString() + 's';
                filterConfig.fields = ['entryType'];
                filterConfig.operators = [{ name: 'Includes', value: 'Includes' }];
                filterConfig.selectedOperator = 'Includes';
                filterConfig.title = 'Specific ' + recipientType.toString() + 's';
                filterConfig.value = [].concat(...recipients.map(item => item.recipientId));
                filterConfig.hideOperators = true;
            }
        }
        return filterConfig;
    }

    /**
     * Used to convert the properties which didn't match with component
     * @param filterItem
     * @param filterConfig
     */
    private _convertPropertiesForFilterConfig(filterItem, filterConfig) {
        switch (filterItem.metadataType.toLowerCase()) {
            case FilterMetadataType.Team.toLowerCase():
            case FilterMetadataType.StartTime.toLowerCase():
                filterConfig.selectedOperator = filterItem.operator.toLowerCase();
                break;
            default:
                break;
        }
    }

    /**
     * set value for filter config
     * @param filterConfig
     * @param ite
     */
    private _setValueForFilterConfig(filterConfig, item) {
        switch (filterConfig.metadataType.toLowerCase()) {
            case FilterMetadataType.EntryType.toLowerCase():
            case FilterMetadataType.NoteType.toLowerCase():
            case FilterMetadataType.EventType.toLowerCase():
                if (item.values.length === 1 && item.values[0] === ALL_VALUE) {
                    filterConfig.value = ALL_VALUE;
                } else {
                    filterConfig.value = item.values;
                }
                break;
            case FilterMetadataType.DisplayDate.toLowerCase():
            case FilterMetadataType.SubmittedDate.toLowerCase():
            case FilterMetadataType.StartTime.toLowerCase():
            case FilterMetadataType.EndTime.toLowerCase():
            case FilterMetadataType.Date.toLowerCase():
                if (filterConfig.selectedOperator.toLowerCase() === 'last' ||
                    filterConfig.selectedOperator.toLowerCase() === 'next') {
                    filterConfig.value = `${item.interval} ${item.unit}`;
                } else if (filterConfig.selectedOperator.toLowerCase() === 'before') {
                    filterConfig.value = item.endDate;
                } else if (filterConfig.selectedOperator.toLowerCase() === 'after') {
                    filterConfig.value = item.startDate;
                } else if (filterConfig.selectedOperator.toLowerCase() === 'range') {
                    filterConfig.value = `${item.startDate} ${item.endDate}`;
                } else if (filterConfig.selectedOperator.toLowerCase() === 'is null' ||
                    filterConfig.selectedOperator.toLowerCase() === 'is not null') {
                    filterConfig.value = '';
                } else if (filterConfig.selectedOperator.toLowerCase() === 'today') {
                    filterConfig.value = item.value;
                }
                break;
            default:
                filterConfig.value = item.values;
                break;
        }
    }
}
