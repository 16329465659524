/**
 * Created by Yoyo Fang on 12/12/18.
 * Description:
 *  A widget for uploading files basing on dropzone.js instead of KendoUI.
 *  KendoUI upload widget is also basing on dropzone.js, simpler but less customizable.
 *  Keep upload widget bussiness irrelevant, consumer should implement bussiness requirement through TamUploadConfig.onXXX methods.
 * ------ maintenance history ------
 * 02/25/2019 Yoyo Fang - expose a upload method for programatically upload file
 * 03/05/2019 Yoyo Fang - triggers onStatusChange after actual event triggered
 * 04/08/2019 Alan Yang - added 'addedfile' event for autoProcessQueue = false case.
 * 04/08/2019 Yoyo Fang - onStatusChange pass file id for dirty check
 * 04/09/2019 Yoyo Fang - file name focused no longer trigger onStatusChange
 *                        only when file name changed onStatusChange's changedFileId!=null
 * 08/19/2019 Yoyo Fang - add track by function for ng if
 * 07/21/2022 Marcus Zhao - If addins mode ,only show rename item. hide download and preview.
 */

import { Component, Input, ViewChild, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import Dropzone from 'dropzone';
import { TamUploadConfig } from './tam-upload.model';
import { AlertWindowService } from '../alert-window/alert-window.service';
import { AttachmentView } from '../../components/attachment-list/attachment-view.model';
import { MAX_PREVIEW_FILE_SIZE, PDFPreviewExtension } from '../../tamalelibs/constants/business.constants';
import { ContextMenuConfig, ContextMenuShowAction } from '../tam-context-menu/tam-context-menu.model';
import { FileHelperService } from '../../services/file-helper.service';
import { AlertButton, AlertBtnTypes } from '../alert-window/alert-window.model';
import { Subscription } from 'rxjs-compat/Subscription';
import { AddInUtilities } from '../../services/utilities/addin-utilities';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';

@Component({
    selector: 'tam-upload', templateUrl: './tam-upload.component.html', styleUrls: ['./tam-upload.component.scss']
})
export class TamUploadComponent implements OnInit, AfterViewInit, OnDestroy {
    // tslint:disable-next-line: no-input-rename
    @Input('config') public inputConfig: TamUploadConfig;
    @ViewChild('dropzone', { static: false }) public dropzone: ElementRef;
    @ViewChild('button', { static: false }) public uploadBtn: ElementRef;
    @ViewChild('hiddenDiv', { static: false }) hiddenDiv: ElementRef;
    @ViewChild('previewsContainer', { static: false }) previewsContainer: ElementRef;

    contextMenuConfig: ContextMenuConfig;

    isDraggable: boolean;
    // only click rename item set it equals trues
    isEditable = false;

    private _dropzone;
    private _defaultConfig;
    private _destroySubscriptions: Array<Subscription> = [];
    private _fileNameCache;
    private _fileExtensionRegex = /(?:\.([^.]+))?$/;

    constructor(
        private _deviceService: DeviceDetectorService,
        private _ref: ChangeDetectorRef,
        private _alertWindow: AlertWindowService,
        private _fileHelperService: FileHelperService,
        private _element: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this._dropzone && this._dropzone.getUploadingFiles().length >= 1) {
            $event.returnValue = true;
        }
    }

    ngOnInit() {
        this.isDraggable = !this._deviceService.isMobile() && !this._deviceService.isTablet() && !AddInUtilities.isOfficeJSLoaded();
        this._initContextMenu();
    }
    ngAfterViewInit() {
        if (!this._dropzone) {
            this._defaultConfig = {
                url: '',
                previewsContainer: this.previewsContainer.nativeElement,
                clickable: this.uploadBtn && this.uploadBtn.nativeElement,
                timeout: 0, // new since dropzone 4.4.0, default 30000 ms, 0 means never timeout,
                readOnly: false,
                hiddenInputContainer: this.hiddenDiv.nativeElement,
            };
            const dropzoneConfig = Object.assign(this._defaultConfig, this.inputConfig);
            const dropzoneElement = this.inputConfig.dropzone ? this.inputConfig.dropzone.nativeElement : this.dropzone.nativeElement;
            if (dropzoneElement.dropzone) {
                delete dropzoneElement.dropzone;
            }
            this._dropzone = new Dropzone(dropzoneElement, dropzoneConfig);
            this._dzAddHandlers(this.inputConfig);
            this._dzAddEventListener(this.inputConfig);
        }
    }

    ngOnDestroy(): void {
        this._dropzone = null;
        this._destroySubscriptions.forEach(item => item.unsubscribe());
        this._destroySubscriptions = [];
    }

    onFileMenuSelect(res) {
        const type = +res.payload.id;
        const attachInfo = res.payload.data;
        // if upload new file ,serverName equals fileId，so replace attachInfo.id. exclude the renaming case (type!==3)
        if (attachInfo.isNew === false && attachInfo.dzid && !attachInfo.isBlastEmail && type !== 3) {
            if (!attachInfo['originalId']) {
                attachInfo['originalId'] = attachInfo.id;
            }
            attachInfo.id = attachInfo.serverName;
        }
        switch (type) {
            case 0:
                this._preview(attachInfo);
                break;
            case 1:
                this._preview(attachInfo, true);
                break;
            case 2:
                this._downloadFile(attachInfo);
                break;
            case 3:
                this.onNameEditing(attachInfo);
                break;
        }
    }

    onShowContextMenu(e: MouseEvent, file, i) {
        if (!file.isCompleted) {
            return;
        }
        let filePreviewDisable = true;
        file['index'] = i;
        const extension = this._fileExtensionRegex.exec(file.name)[1];
        if (extension && PDFPreviewExtension.includes(extension.toLowerCase())) {
            filePreviewDisable = false;
        }
        if (this.inputConfig.isPreviewable === false) {
            filePreviewDisable = true;
        }
        let isFileDownloadDisabled = false;
        if (this.inputConfig.isDownloadable === false) {
            isFileDownloadDisabled = true;
        }
        // Only tamaleweb need this configuration.
        if (!AddInUtilities.isOfficeEnvironment()) {
            this.contextMenuConfig.items[0].disabled = this.contextMenuConfig.items[1].disabled = filePreviewDisable;
            this.contextMenuConfig.items[2].disabled = isFileDownloadDisabled;
            this.contextMenuConfig.items[3].disabled = this.inputConfig.readOnly;
        }
        this._changeDetectorRef.detectChanges();
        this.contextMenuConfig.show$.next(new ContextMenuShowAction(e, file));
    }



    onNameEditing(file) {
        if (this.inputConfig.readOnly) {
            return;
        }
        this.isEditable = true;
        this._changeDetectorRef.detectChanges();
        const regexResult = this._fileExtensionRegex.exec(file.name);
        this._fileNameCache = {
            name: file.name.substring(0, regexResult.index),
            extension: regexResult[0],
            index: regexResult.index,
            fullName: file.name
        };
        file.name = this._fileNameCache.name;
        this.inputConfig.onStatusChange('rename', 1, this.inputConfig);
        setTimeout(() => {
            this._element.nativeElement.querySelector('.file-name' + file.index).focus();
        });

    }

    onNameEditingDone(file, index) {
        if (this.inputConfig.readOnly) {
            return;
        }
        this.isEditable = false;
        if (!this.inputConfig.onNameChange(
            file.name + this._fileNameCache.extension,
            this._fileNameCache.fullName, this.inputConfig, index
        )) {
            file.name = this._fileNameCache.fullName;
        }
        let changedFileId = null;
        if (this.inputConfig.files[index].name !== this._fileNameCache.fullName) {
            changedFileId = file.dzid;
        }
        this.inputConfig.onStatusChange('rename', -1, this.inputConfig, changedFileId);
    }

    onKeyDown(event) {
        if (this.inputConfig.readOnly) {
            return;
        }
        const keyCode = event.keyCode || event.which;
        // enter key and tab key => name edit done
        if ([13, 9].includes(keyCode)) {
            event.preventDefault();
            // Run in the next tick to ensure the element is still present
            setTimeout(() => {
                event.target.blur();
            }, 0);
        }
    }

    remove(file, index, event) {
        const self = this;
        if (file.progress !== '100%' || !file.serverName) {
            // file is still uploading, confirm user want to cancel
            const warningText = 'Are you sure you want to cancel this upload?';
            const subscription = self._alertWindow.warn('You have unsaved changes', [warningText]).subscribe((result: boolean) => {
                if (result) {
                    if (self._dropzone.getUploadingFiles()) {
                        const uploadings = self._dropzone.getUploadingFiles();
                        if (Array.isArray(uploadings) && uploadings.length > 0) {
                            // for the case on tamale web
                            const dzfile = uploadings.filter((item) => item.upload.uuid === file.dzid)[0];
                            self.inputConfig.files.splice(index, 1);
                            self._dropzone.cancelUpload(dzfile);
                        } else {
                            // for the case in office add-in.
                            // need to remove the file if the use clicks the delete icon when the file is still uploading
                            if (self.inputConfig.onRemove(file, self._dropzone, self.inputConfig)) {
                                self.inputConfig.files.splice(index, 1);
                                self._dropzone.removeFile(file);
                                self.inputConfig.onStatusChange('remove', 0, self.inputConfig, file.dzid);
                                self._changeDetectorRef.detectChanges();
                            }
                        }
                    }
                }
                subscription.unsubscribe();
            });
        } else {
            // file is uploaded, delete
            if (this.inputConfig.onRemove(file, this._dropzone, this.inputConfig)) {
                this.inputConfig.files.splice(index, 1);
                this._dropzone.removeFile(file);
                this.inputConfig.onStatusChange('remove', 0, this.inputConfig, file.dzid);
                this._changeDetectorRef.detectChanges();
            }
        }
        event.stopImmediatePropagation();
    }

    trackByFn(index, item) {
        return item.dzid;
    }


    private _dzAddHandlers(inputConfig) {
        inputConfig.upload = (e) => {
            this._dropzone.listeners[0].events.drop(e);
        };
    }
    private _dzAddEventListener(inputConfig) {
        this._dropzone.on('addedfile', function (file, xhr, formData) {
            inputConfig.onStatusChange('add', -1, inputConfig, file.dzid);
            inputConfig.onAddedfile(file, xhr, inputConfig);
        });

        this._dropzone.on('sending', function (file, xhr, formData) {
            inputConfig.onSending(file, xhr, inputConfig);
            inputConfig.onStatusChange('upload', 1, inputConfig, inputConfig.files[inputConfig.files.length - 1].dzid);
        });
        this._dropzone.on('success', (function (ref) {
            return function (file, responseObject) {
                const successIndex = inputConfig.onSuccess(file, responseObject, inputConfig);
                inputConfig.onStatusChange('upload', -1, inputConfig, file.dzid);
                // for exposing the upload call
                if (!ref['destroyed']) {
                    ref.detectChanges();
                }
            };
        })(this._ref));
        this._dropzone.on('uploadprogress', function (file, progress, bytesSent) {
            inputConfig.onProgress(file, progress, inputConfig);
        });
        this._dropzone.on('error', (function (dropzone) {
            // closure packs dropzone for calling removeFile method
            return function (file, errorMessage, xhr) {
                // user cancel processing upload is not an error
                if (file.status !== 'canceled') {
                    dropzone.removeFile(file);
                    const index = inputConfig.files.reduce((acc, cur, idx) => cur.dzid === file.upload.uuid ? idx : acc, -1);
                    if (index < 0 || inputConfig.files.splice(index, 1)) {
                        inputConfig.onError(errorMessage, file);
                    }
                }
                if (file.upload && file.upload.progress >= 0 && file.upload.progress <= 100) {
                    inputConfig.onStatusChange('upload', -1, inputConfig, file.dzid);
                }
            };
        })(this._dropzone));
    }

    private _downloadFile(file) {
        if (file) {
            const regexResult = this._fileExtensionRegex.exec(file.name);
            const fileExtension = regexResult[1];
            const fileName = file.name.substring(0, regexResult.index);
            this._fileHelperService.downloadFile$.next();
            this._fileHelperService.downloadFileNew(fileName, fileExtension, file.id);
        }
    }

    private _initContextMenu() {
        this.contextMenuConfig = new ContextMenuConfig();
        this.contextMenuConfig.stateful = false;
        this.contextMenuConfig.items = [
            { id: 0, text: StringLiteralsPipe.translate('general.preview'), disabled: false },
            { id: 1, text: StringLiteralsPipe.translate('general.preview_in_other_tab'), disabled: false },
            { id: 2, text: StringLiteralsPipe.translate('general.download'), disabled: false },
            { id: 3, text: StringLiteralsPipe.translate('general.rename'), disabled: false },
        ];
        if (AddInUtilities.isOfficeEnvironment()) {
            this.contextMenuConfig.items[0].hidden = true;
            this.contextMenuConfig.items[1].hidden = true;
            this.contextMenuConfig.items[2].hidden = true;
        }
        this._destroySubscriptions.push(
            this.contextMenuConfig.events$.subscribe(event => this.onFileMenuSelect(event))
        );
    }

    // preview the pdf file according to service
    private _preview(file, isPreviewInNewTab = false) {
        if (file) {
            const regexResult = this._fileExtensionRegex.exec(file.name);
            const fileExtension = regexResult[1];
            const fileId = file.id;
            const fileLink = '';
            const fileName = file.name.substring(0, regexResult.index);
            if (PDFPreviewExtension.includes(fileExtension.toLowerCase())) {
                // use file url
                if (isPreviewInNewTab) {
                    this._fileHelperService.previewFileInNewTab(fileId, fileLink, fileExtension, fileName, true, false);
                } else {
                    this._fileHelperService.previewFile(fileId, fileLink, fileExtension, fileName, false, true);
                }
            }
        }
    }
}
